@use '@angular/material' as mat;

@mixin bee-form-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .back-container {
    color: mat.get-color-from-palette($primary);
    &:hover {
      opacity: .54;
    }
  }

  .warn-text {
    color: mat.get-color-from-palette($warn);
  }

  .inside-modal-title, .form-title {
    &.primary-colored-title {
      color: mat.get-color-from-palette($primary);
    }
  }

  .full-height-container {
    form {
      .button-container {
        background: mat.get-color-from-palette($background, 'card');
      }
    }
  }
}
