@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin bracelit-mobile-header-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mobile-header-container {
    // background-color: mat.get-color-from-palette($primary) !important;
    background-color: #FFFFFF !important;
    border-bottom: 1px solid #C0C4CA !important;
  }

  .mat-badge-content {
    color: mat.get-color-from-palette($primary) !important;
  }
}
