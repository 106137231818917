//Bracelit classes

.page-container {
  width: 100%;
  height: 100%;
}

.main-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  display: flex;
  align-items: flex-start;
  place-content: space-between center;
  box-sizing: border-box;
  padding-bottom: 25px;
  padding-top: 60px;
}

.main-container-auth {
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0 20px;
  overflow: scroll;
  display: flex;
  align-items: flex-start;
  place-content: space-between center;
  box-sizing: border-box;
  padding-bottom: 25px;
  padding-top: 30px;
}

.main-container2 {
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  display: flex;
  align-items: flex-start;
  place-content: space-between center;
  box-sizing: border-box;
}

.mat-raised-button {
  padding: 4px 30px !important;
  border-radius: 3px !important;
  line-height: 29.382px !important;
  height: 38px !important;
}

.larger-button {
  padding: 4px 105px !important;
}

.card-list-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  max-height: 100%;
  // height: 100%;
  overflow: auto;

  .card-list {
    padding: 12px;
    display: flex;
    max-width: 80%;
    flex-flow: row wrap;
    padding-left: 20px;
    align-items: center;
    justify-content: center;
  }
}

.card-list-container-auto {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  max-height: 100%;
  // height: 100%;
  overflow: auto;

  .card-list {
    padding: 12px;
    display: flex;
    max-width: 80%;
    flex-flow: row wrap;
    padding-left: 20px;
    align-items: center;
    justify-content: center;
  }
}

.bracelit-card-wristbands {
  margin-right: 20px;
  margin-bottom: 20px;
}

.bracelit-card, .bracelit-card-wristbands {
  padding: 0 !important;
  //opacity: 0.94 !important;
  border-radius: 7px !important;
  box-shadow: 0 2px 9px 4px rgba(24, 90, 125, 0.19) !important;
  // margin-bottom: 20px; // vertical space between cards

  .bracelit-card-content {
    padding: 7px !important;
    display: flex;
    flex-flow: row wrap;

    .bracelit-card-info-container {
      //width: calc(100% - 38px);
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .bracelit-card-title {
        width: 100%;
        height: 20px;
        margin-bottom: 10px;
        text-align: center !important;
      }

      .bracelit-card-info {
        width: 100%;
        height: 20px;
        display: flex;

        div {
        }

        .img-container {
          display: flex;
          width: 24px;
          // height: 100% !important;
          justify-content: flex-start;
          align-items: center;
          padding-left: 5px;

          img {
            height: 16px;
            width: 16px;
            opacity: 0.5;
          }
        }

      }
    }

    .bracelit-card-icon-container {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;

      img {
        width: 150px;
        height: 100px;
      }
    }

    .bracelit-card-row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      width: 100%;

      .bracelit-card-title {
        width: calc(100% - 35px);
      }

      .img-container {
        height: 100%;
        width: 30px;
        display: flex;
        align-items: end;

        img {
          height: 25px;
          width: 25px;
        }
      }

      .number-container {
        height: 100%;
        margin-left: 4px;
        display: flex;
        align-items: end;

        span {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .bracelit-card-row-second {
      align-items: flex-end;
      justify-content: flex-end;
      height: 40px;

      div {
        margin-left: 6px;

        &:nth-child(2) {
          margin-left: 18px;
        }
      }
    }
  }
}

.bracelit-card-2 {
  border-radius: 7px !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25) !important;
  margin-bottom: 20px; // vertical space between cards
  .bracelit-card-content {
    padding: 18px 26px;
  }

  .bracelit-card-content-more-margins {
    padding: 2px 11px;
  }
}

.wristband-list-title {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-height-with-title {
  max-height: calc(100% - 80px) !important;
  height: calc(100% - 80px);
}

.max-height-with-title-2 {
  max-height: calc(100% - 170px) !important;
  height: calc(100% - 170px);
}

.card-list-container-custom-max-height {
  max-height: calc(100% - 80px);
  overflow: scroll;
}

.add-card-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bracelit-card-wristbands {
  cursor: pointer;
}

.bracelit-shape-card {
  border-radius: 3px;
  display: flex;
  cursor: pointer;

  .add-card-container {
    padding: 5.5px 45px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .add-card-scan-container {
      display: flex;


      .scan-text-container {
        color: white;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
      }
    }

    .wristband-icon-container {
      display: flex;
      width: 38px;
      align-items: center;
      justify-content: center;
    }
  }
}

.margin-bottom-7 {
  margin-bottom: 7px;
}

.text-adjust-bottom {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.big-button-container {
  height: 45px;
  width: 300px;
  flex: none !important;

  button {
    width: 100%;
  }
}

.custom-modal-padding {
  mat-dialog-container {
    padding: 24px 0 !important;
  }
}

.custom-modal-padding-voucher {
  mat-dialog-container {
    padding: 24px !important;
  }
}

.padding-without-bottom {
  mat-dialog-container {
    padding-bottom: 0 !important;
  }
}

.modal-no-padding {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.no-background-dialog {
  mat-dialog-container {
    //background: none !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
  }
}

.modal-like-bracelit-card {
  .mat-dialog-container {
    border-radius: 7px !important;
    box-shadow: 0 2px 9px 4px rgba(24, 90, 125, 0.19) !important;
  }
}

.bracelit-background-dialog {
  mat-dialog-container {
    height: 100%;
    min-height: 100%;
    margin: 0;
    background-image: url("~assets/bracelit/bg_whole.png") !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-size: 100% 100% !important;
  }
}

#theme-container{
  // background-image: url("~assets/bracelit/bg_whole.png");
  //background-color: #e3ebef"
  background-repeat: no-repeat;
  background-position: top;
  //background-size: contain;
  background-size: 100% 100%;
}

#bg_whole {
  background-image: url("~assets/bracelit/bg_whole.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
}

.bg_whole {
  mat-dialog-container {
    height: 100%;
    min-height: 100%;
    margin: 0;
    //background-image: url("~assets/bracelit/bg_whole.png") !important;
    background-image: url("~assets/bracelit/bg_whole.png") !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-size: 100% 100% !important;
  }
}

.full-screen-dialog {
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;

  mat-dialog-container {
    padding: 0 !important;
  }
}

.disabled-button {
  opacity: 0.4;
}

.disabled-button-bonos {
  // filter: brightness(0) saturate(100%);
  filter: brightness(0) saturate(100%) invert(91%) sepia(10%) saturate(132%) hue-rotate(177deg) brightness(87%) contrast(86%);
  // opacity: 0.4;
}

// ESTADO VACÍOS
.empty-state-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  place-content: center;
  width: 100%;
  height: 100%;

  .empty-state-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    img {
      height: 64px;
      width: 64px;
      opacity: 0.5;
    }
  }

  .empty-state-container-main-text {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    margin-bottom: 10px;
  }

  .empty-state-secondary-text-container {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .empty-state-button-container {
    height: 70px;
    width: 100%;
    position: absolute;
    //bottom: 48px;
    bottom: 23px;
  }
}

.bracelit-preloader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;

  bracelit-preloader {
    width: 100%;
  }

  .preloader-text {
    width: 100%;
  }
}

.sidebar-button {
  display: flex !important;
  border-radius: 0 !important;
  width: 100%;
  overflow: hidden !important;
  min-width: 0 !important;
  line-height: unset !important;
  padding: 20px 7px 20px 17px !important;
  transition: all 350ms $easing;

  .custom-icon {
    margin: 0;
  }

  .custom-icon2 {
    margin: 0;
  }
}
