@use '@angular/material' as mat;

@mixin theming-classes($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);


  $primary-50: mat.get-color-from-palette($primary, 50);
  $primary-100: mat.get-color-from-palette($primary, 100);
  $primary-200: mat.get-color-from-palette($primary, 200);
  $primary-800: mat.get-color-from-palette($primary, 800);
  $secondary: mat.get-color-from-palette($primary, A100);
  $tertiary: mat.get-color-from-palette($primary, A400);
  $accent-200: mat.get-color-from-palette($accent, 200);
  $primary-contrast: mat.get-contrast-color-from-palette($primary, 500);

  #circle-lleno {
    background: $secondary;
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }

  .primary-contrast-text-color {
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .marine-color {
    color: #0f223e !important;
  }

  .primary-color {
    color: mat.get-color-from-palette($primary) !important;
  }

  .gray-color {
    color: #C0C4CA !important;
  }

  .primary-color-200 {
    color: $primary-200 !important;
  }

  svg: primary-fill-svg {
    fill: mat.get-color-from-palette($primary) !important;
  }

  .primary-fill {
    fill: mat.get-color-from-palette($primary) !important;
  }

  .secondary-fill {
    filter: brightness(0) saturate(100%) invert(50%) sepia(93%) saturate(418%) hue-rotate(141deg) brightness(88%) contrast(90%);
  }

  .primary-border-50 {
    border-color: $primary-50 !important;
  }

  .primary-border-100 {
    border-color: $primary-100 !important;
  }

  .grey-border-100 {
    border-color: #C0C4CA !important;
  }

  .transparent-border-100 {
    border-color: transparent !important;
  }

  .primary-border-200 {
    border-color: $primary-200 !important;
  }

  .primary-indicator {
    .mat-expansion-indicator:after {
      color: mat.get-color-from-palette($primary);
    }
  }

  .contrast-background {
    background-color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .primary-color-small {
    color: mat.get-color-from-palette($primary, 0.6);
  }

  .marine-background {
    background-color: #0f223e !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: mat.get-color-from-palette($primary) !important;
  }

  .primary-background {
    background-color: mat.get-color-from-palette($primary) !important;
  }

  .primary-background-50 {
    background-color: $primary-50 !important;
  }

  .primary-background-200 {
    background-color: $primary-200 !important;
  }

  .primary-background-800 {
    background-color: $primary-800 !important;
  }


  .white-color {
    color: white !important;
  }

  .secondary-color {
    color: $secondary !important;
  }

  .secondary-background {
    background-color: $secondary !important;
  }

  .secondary-background-semi {
    background-color: mat.get-color-from-palette($primary) !important;
    background-image: linear-gradient(to right, transparent 50% , #ffffff 50%) !important;
    border-color: mat.get-color-from-palette($primary) !important;
    border-style: solid !important;
    border-width: thin;
    box-sizing: border-box;
  }

  .secondary-background-full {
    background-color: mat.get-color-from-palette($primary) !important;
    background-image: linear-gradient(to right, transparent 50% , transparent 50%) !important;
    border-color: mat.get-color-from-palette($primary) !important;
    border-style: solid !important;
    border-width: thin;
    box-sizing: border-box;
  }

  .secondary-background-empty {
    background-color: mat.get-color-from-palette($primary) !important;
    background-image: linear-gradient(to right, #ffffff 50% , #ffffff 50%) !important;
    border-color: mat.get-color-from-palette($primary) !important;
    border-style: solid !important;
    border-width: thin;
    box-sizing: border-box;
  }

  .secondary-border {
    background-color: $secondary !important;
  }

  .tertiary-color {
    color: $tertiary !important;
  }

  button.button-tertiary {
    background-color: $tertiary !important;
    color: mat.get-color-from-palette($primary) !important;
    border: 1px solid mat.get-color-from-palette($primary) !important;
  }

  span.red-text {
    color: #DB1F35 !important;
  }

  .tertiary-background {
    background-color: $tertiary !important;
  }

  .tertiary-border {
    border-color: $tertiary !important;
  }

  .dark-background {
    background-color: black !important;
  }

  .white-opacity-background {
    background-color: rgba(255, 255, 255, 0.94);
  }

  .white-background {
    background-color: rgba(255, 255, 255, 1) !important;
  }

  .primary-soft-background {
    background-color: mat.get-color-from-palette($primary, 0.1) !important;

    &:hover {
      background-color: mat.get-color-from-palette($primary, 0.15) !important;
    }
  }

  .primary-border {
    border-color: mat.get-color-from-palette($primary) !important;
  }

  .default-background {
    background-color: mat.get-color-from-palette($background, background) !important;
  }

  .hover-primary {
    &:hover {
      color: mat.get-color-from-palette($primary) !important;
    }
  }

  .hover-primary-background {
    &:hover {
      background-color: mat.get-color-from-palette($primary) !important;
    }
  }

  .hover-background {
    &:hover {
      background: mat.get-color-from-palette($background, hover, 0.03) !important;
    }
  }

  .warn-color {
    color: mat.get-color-from-palette($warn) !important;
  }

  .warn-border {
    border-color: mat.get-color-from-palette($warn) !important;
  }

  .accent-color {
    color: mat.get-color-from-palette($accent) !important;
  }

  .accent-background {
    background-color: mat.get-color-from-palette($accent) !important;
  }

  .foreground-text-color {
    color: mat.get-color-from-palette($foreground, text) !important;
  }

  .foreground-secondary-text-color {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }

  .foreground-secondary-text-color-soft {
    color: mat.get-color-from-palette($foreground, secondary-text, 0.36);
  }

  .divider-color {
    border-color: mat.get-color-from-palette($foreground, divider);
  }

  .dialog-table {
    tr {
      border-top-color: mat.get-color-from-palette($foreground, divider);

      .action-icon {
        color: transparent;
      }

      .dialog-custom-input {
        border-bottom-color: mat.get-color-from-palette($foreground, secondary-text, 0.25);

        &:hover {
          border-bottom-color: mat.get-color-from-palette($foreground, text);
        }

        &:focus {
          border-bottom-color: mat.get-color-from-palette($primary);
        }
      }

      &:hover {
        background: mat.get-color-from-palette($background, hover, 0.03);

        .action-icon {
          color: mat.get-color-from-palette($foreground, secondary-text);
        }

        .dialog-custom-input {
          background: transparent;
        }
      }
    }
  }

  .dialog-div-container {
    .dialog-div-container-remove {
      color: transparent;
    }

    &:hover {
      .dialog-div-container-remove {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }
  }

  .card-table {
    tr {
      border-top-color: mat.get-color-from-palette($foreground, divider);

      .action-icon {
        color: transparent;

        button {
          color: transparent;
        }
      }

      &:hover {
        background: mat.get-color-from-palette($background, hover, 0.03);

        .action-icon {
          color: mat.get-color-from-palette($foreground, secondary-text);

          button {
            color: mat.get-color-from-palette($primary);
          }

          &:hover {
            color: mat.get-color-from-palette($primary);
          }
        }
      }
    }
  }

  .dashboard-card-content {
    tr {
      border-top-color: mat.get-color-from-palette($foreground, divider);

      .action-icon {
        color: transparent;
      }

      &:hover {
        background: mat.get-color-from-palette($background, hover, 0.03);

        .action-icon {
          color: mat.get-color-from-palette($foreground, secondary-text);

          &:hover {
            color: mat.get-color-from-palette($primary);
          }
        }
      }
    }

    .resume-table {
      border-top-color: mat.get-color-from-palette($foreground, divider);
    }
  }

  .no-hover-table {
    tr {
      &:hover {
        background: mat.get-color-from-palette($background, 'card');
      }
    }
  }

  .custom-standard-chip {
    background-color: mat.get-color-from-palette($primary, 0.1) !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .help-dashboard-card {
    .mat-icon {
      color: mat.get-color-from-palette($primary);
    }

    &:hover {
      background-color: mat.get-color-from-palette($primary);

      .mat-icon, .help-dashboard-card-text {
        color: white;
      }
    }
  }

  .room-open {
    background-color: mat.get-color-from-palette($primary) !important;
    color: white !important;

    &:hover {
      background-color: mat.get-color-from-palette($primary) !important;
      color: white !important;
    }
  }

  #public-office-page-groups {
    .public-group-button-hover {
      background-color: mat.get-color-from-palette($primary) !important;
      color: white !important;
    }
  }

  .notification-container {
    border-color: mat.get-color-from-palette($foreground, divider);

    .show-tip {
      color: transparent;
    }

    &:hover {
      background: mat.get-color-from-palette($background, hover, 0.03);

      .show-tip {
        color: mat.get-color-from-palette($primary);
      }
    }

    .notification-subject {
      color: mat.get-color-from-palette($foreground, text, 0.75);

      .mat-icon {
        color: mat.get-color-from-palette($primary);
      }
    }

    .notification-date {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }

  .message-container {
    border-color: mat.get-color-from-palette($foreground, divider);

    .show-tip {
      color: transparent;
    }

    &:hover {
      background: mat.get-color-from-palette($background, hover, 0.03);

      .show-tip {
        color: mat.get-color-from-palette($primary);
      }
    }

    .message-subject {
      color: mat.get-color-from-palette($foreground, text, 0.75);

      .mat-icon {
        color: mat.get-color-from-palette($primary);
      }
    }

    .message-date {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }

  .no-results-container {
    .no-results-icon-container {
      background: mat.get-color-from-palette($foreground, divider, 0.06);

      .mat-icon {
        color: mat.get-color-from-palette($foreground, divider, 0.12);
      }
    }

    .no-results-title {
      color: mat.get-color-from-palette($foreground, divider, 0.22);
    }
  }

  //CUSTOMIZING INPUTS FOR BRACELIT

  // INPUT OUTLINE color primary - 200
  .primary-200-outline {
    input {
      caret-color: $primary-200;
      color: mat.get-color-from-palette($primary);
    }

    label {
      color: $primary-200;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: $primary-200 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: $primary-200;
    }
  }

  // OUTLINE PRIMARY
  mat-form-field .primary-outline {
    label {
      color: mat.get-color-from-palette($primary) !important;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: mat.get-color-from-palette($primary) !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: mat.get-color-from-palette($primary) !important;
    }
  }

  // INPUT PRIMARY (use in a mat-form-field)
  .input-primary {
    .mat-form-field-wrapper {
      .mat-form-field-underline {
        // background-color: $primary-200 !important;
        background-color: #0f223e !important;
      }
    }

    input {
      caret-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary);
    }

    label {
      color: mat.get-color-from-palette($primary);
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: mat.get-color-from-palette($primary) !important;
    }

    //.mat-form-field-appearance-outline .mat-form-field-outline {
    //  color: $primary-200;
  }

  // CHECKBOX
  .bracelit-primary-checkbox {
    label {
      .mat-checkbox-inner-container {
        height: 20px;
        width: 20px;

        .mat-checkbox-frame {
          border-color: mat.get-color-from-palette($primary);
          border-width: 1px;
        }

        .mat-checkbox-ripple {
          .mat-checkbox-persistent-ripple {
            background-color: transparent !important;
          }
        }
      }

      .mat-checkbox-label {
        padding-top: 3px;
      }
    }
  }

  // BEE-HEADER
  .header-primary-background {
    .mobile-header-container {
      // background-color: mat.get-color-from-palette($primary);
      background-color: #C0C4CA;
    }
  }

  .boton-recarga{
    background-color: mat.get-color-from-palette($primary);
    border-color: transparent !important;
    border-radius: 3px !important;
    width: 23% !important;
    height: auto;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: $primary-contrast !important;
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
  }

  .boton-recarga.blanco{
    background-color: #D6DBE2 !important;
    border-color: transparent !important;
    border-radius: 3px !important;
    width: 23% !important;
    height: auto;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .boton-pagar{
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: center !important;
    border-radius: 3px !important;
    width: 125px !important;
    height: 38px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: mat.get-contrast-color-from-palette($primary, 400) !important;
  }

  .contenedor-pag-completa{
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    // height: 100% !important;
    min-height: calc(100% - 50px) !important;
    // max-height: 100% !important;
    position: relative !important;
    padding-top: 50px;
  }

  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    height: 50px;
    display: flex;
    flex-direction: row;
    z-index: 10;
    button {
      padding: 0 !important;
    }
  
    .mat-button {
      min-width: unset !important;
    }
  
    .left-icon-container {
      height: 100%;
      min-width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px !important;
    }
    
    .central-container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .right-icon-container {
      height: 100%;
      min-width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px !important;
  
      .mat-badge-content {
        top: 3px;
        left: 8px;
        width: 15px;
        height: 15px;
        line-height: 17px;
      }
    }
  }

  .input-recarga{
    border: 1px solid #0f223e;
    border-radius: 3px;
    background: transparent;
    padding: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    place-content: center;
    // min-height: 40px !important;
    height: auto !important;
    color: #0f223e !important;

    ::ng-deep .mat-form-field-underline {
      display: none;
      //margin-bottom: -5px
    }



    input{
      //margin-bottom: -10%;
      //margin-top: 4%;
      border: none;
      border-bottom: none !important;
      outline: none !important;
      background: transparent;
      //flex: 1;
      margin-left: 14px;
      margin-right: 14px;
      width: 100% !important;
      display: flex;
      align-items: center;
    }

    input::placeholder {
      color: #0f223e !important;
      font-weight: 400 !important;
    }

  }

  .input-recarga-marine{
    border: 1px solid mat.get-color-from-palette($primary) !important;
    border-radius: 3px;
    background: transparent;
    padding: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    place-content: center;
    min-height: 38px !important;
    height: auto !important;
    color: #0f223e !important;

    ::ng-deep .mat-form-field-underline {
      display: none;
      //margin-bottom: -5px
    }



    input{
      //margin-bottom: -10%;
      //margin-top: 4%;
      border: none;
      border-bottom: none !important;
      outline: none !important;
      background: transparent;
      //flex: 1;
      margin-left: 14px;
      margin-right: 14px;
      width: 100% !important;
      display: flex;
      align-items: center;
    }

    input::placeholder {
      color: #0f223e !important;
      font-weight: 400 !important;
    }

  }

  .color-secondary {
    filter: brightness(0) saturate(100%) invert(50%) sepia(93%) saturate(418%) hue-rotate(141deg) brightness(88%) contrast(90%);
  }

  .color-primary {
    color: mat.get-color-from-palette($primary) !important;
    fill: mat.get-color-from-palette($primary) !important;
  }

  .color-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(93%) saturate(0%) hue-rotate(141deg) brightness(100%) contrast(100%);
  }

  .linea-debajo {
    border-bottom: solid !important;
    border-bottom-color: #C0C4CA !important;
    border-bottom-width: 1px !important;
  }

}
