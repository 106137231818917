// NEW PALETTES 2023
$bracelit: (
  50 : #0F223E, // border primary_50
  100 : #0F223E, // border primary_100
  200 : #0F223E, // border primary_200
  300 : #0F223E,
  400 : #0F223E,
  500 : #0F223E, // primary
  600 : #0F223E,
  700 : #0F223E,
  800 : #0F223E, // fill_primary_800
  900 : #0F223E,
  A100 : #ffffff, // secondary
  A200 : #0F223E,
  A400 : #0F223E, // tertiary
  A700 : #0F223E,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #0F223E,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$greyblue: (
  50 : #6895AE, // border primary_50
  100 : #6895AE, // border primary_100
  200 : #6895AE, // border primary_200
  300 : #6895AE,
  400 : #6895AE,
  500 : #6895AE, // primary
  600 : #6895AE,
  700 : #6895AE,
  800 : #6895AE, // fill_primary_800
  900 : #6895AE,
  A100 : #6895AE, // tertiary
  A200 : #6895AE,
  A400 : #6895AE, // secondary
  A700 : #6895AE,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$orange: (
  50 : #FF8A00, // border primary_50
  100 : #FF8A00, // border primary_100
  200 : #FF8A00, // border primary_200
  300 : #FF8A00,
  400 : #FF8A00,
  500 : #FF8A00, // primary
  600 : #FF8A00,
  700 : #FF8A00,
  800 : #FF8A00, // fill_primary_800
  900 : #FF8A00,
  A100 : #FF8A00, // tertiary
  A200 : #FF8A00,
  A400 : #FF8A00, // secondary
  A700 : #FF8A00,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$blue: (
  50 : #0033B6, // border primary_50
  100 : #0033B6, // border primary_100
  200 : #0033B6, // border primary_200
  300 : #0033B6,
  400 : #0033B6,
  500 : #0033B6, // primary
  600 : #0033B6,
  700 : #0033B6,
  800 : #0033B6, // fill_primary_800
  900 : #0033B6,
  A100 : #0033B6, // tertiary
  A200 : #0033B6,
  A400 : #0033B6, // secondary
  A700 : #0033B6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$red: (
  50 : #E42900, // border primary_50
  100 : #E42900, // border primary_100
  200 : #E42900, // border primary_200
  300 : #E42900,
  400 : #E42900,
  500 : #E42900, // primary
  600 : #E42900,
  700 : #E42900,
  800 : #E42900, // fill_primary_800
  900 : #E42900,
  A100 : #E42900, // tertiary
  A200 : #E42900,
  A400 : #E42900, // secondary
  A700 : #E42900,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$light-purple: (
  50 : #7071DB, // border primary_50
  100 : #7071DB, // border primary_100
  200 : #7071DB, // border primary_200
  300 : #7071DB,
  400 : #7071DB,
  500 : #7071DB, // primary
  600 : #7071DB,
  700 : #7071DB,
  800 : #7071DB, // fill_primary_800
  900 : #7071DB,
  A100 : #7071DB, // tertiary
  A200 : #7071DB,
  A400 : #7071DB, // secondary
  A700 : #7071DB,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$light-blue: (
  50 : #37BCE5, // border primary_50
  100 : #37BCE5, // border primary_100
  200 : #37BCE5, // border primary_200
  300 : #37BCE5,
  400 : #37BCE5,
  500 : #37BCE5, // primary
  600 : #37BCE5,
  700 : #37BCE5,
  800 : #37BCE5, // fill_primary_800
  900 : #37BCE5,
  A100 : #37BCE5, // tertiary
  A200 : #37BCE5,
  A400 : #37BCE5, // secondary
  A700 : #37BCE5,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$purple: (
  50 : #7502BB, // border primary_50
  100 : #7502BB, // border primary_100
  200 : #7502BB, // border primary_200
  300 : #7502BB,
  400 : #7502BB,
  500 : #7502BB, // primary
  600 : #7502BB,
  700 : #7502BB,
  800 : #7502BB, // fill_primary_800
  900 : #7502BB,
  A100 : #7502BB, // tertiary
  A200 : #7502BB,
  A400 : #7502BB, // secondary
  A700 : #7502BB,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$yellow: (
  50 : #FEC629, // border primary_50
  100 : #FEC629, // border primary_100
  200 : #FEC629, // border primary_200
  300 : #FEC629,
  400 : #FEC629,
  500 : #FEC629, // primary
  600 : #FEC629,
  700 : #FEC629,
  800 : #FEC629, // fill_primary_800
  900 : #FEC629,
  A100 : #FEC629, // tertiary
  A200 : #FEC629,
  A400 : #FEC629, // secondary
  A700 : #FEC629,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #0F223E,
    500 : #0F223E,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$pink: (
  50 : #D81B60, // border primary_50
  100 : #D81B60, // border primary_100
  200 : #D81B60, // border primary_200
  300 : #D81B60,
  400 : #D81B60,
  500 : #D81B60, // primary
  600 : #D81B60,
  700 : #D81B60,
  800 : #D81B60, // fill_primary_800
  900 : #D81B60,
  A100 : #D81B60, // tertiary
  A200 : #D81B60,
  A400 : #D81B60, // secondary
  A700 : #D81B60,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$green: (
  50 : #009655, // border primary_50
  100 : #009655, // border primary_100
  200 : #009655, // border primary_200
  300 : #009655,
  400 : #009655,
  500 : #009655, // primary
  600 : #009655,
  700 : #009655,
  800 : #009655, // fill_primary_800
  900 : #009655,
  A100 : #009655, // tertiary
  A200 : #009655,
  A400 : #009655, // secondary
  A700 : #009655,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mahou: (
  50 : #13322b, // border primary_50
  100 : #13322b, // border primary_100
  200 : #13322b, // border primary_200
  300 : #13322b,
  400 : #13322b,
  500 : #13322b, // primary
  600 : #13322b,
  700 : #13322b,
  800 : #13322b, // fill_primary_800
  900 : #13322b,
  A100 : #13322b, // tertiary
  A200 : #13322b,
  A400 : #13322b, // secondary
  A700 : #13322b,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$light-green: (
  50 : #60CE2C, // border primary_50
  100 : #60CE2C, // border primary_100
  200 : #60CE2C, // border primary_200
  300 : #60CE2C,
  400 : #60CE2C,
  500 : #60CE2C, // primary
  600 : #60CE2C,
  700 : #60CE2C,
  800 : #60CE2C, // fill_primary_800
  900 : #60CE2C,
  A100 : #60CE2C, // tertiary
  A200 : #60CE2C,
  A400 : #60CE2C, // secondary
  A700 : #60CE2C,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// NUEVOS COLORES
// BRACELIT PRIMARY
$bee-bracelit: (
  50 : #e2e4e8,
  100 : #b7bdc5,
  200 : #c0c4ca,
  300 : #576478,
  400 : #33435b,
  500 : #0f223e,
  600 : #0d1e38,
  700 : #0b1930,
  800 : #081428,
  900 : #040c1b,
  A100 : #18A1B9,
  A200 : #2660ff,
  // A400 : #0041f2,
  A400 : #5df897,
  A700 : #5DF897,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// AXXIST
$axxist: (
  50 : #e2e4e8,
  100 : #b7bdc5,
  200 : #87919f,
  300 : #576478,
  400 : #33435b,
  500 : #0f223e,
  600 : #0d1e38,
  700 : #0b1930,
  800 : #081428,
  900 : #040c1b,
  A100 : #18A1B9,
  A200 : #2660ff,
  // A400 : #0041f2,
  A400 : #5df897,
  A700 : #5DF897,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

/* AYALA POLO */
$ayalapolo: (
    50 : #e2e5e7,
    100 : #b7bec4,
    200 : #87929d,
    300 : #566675,
    400 : #324658,
    500 : #0e253a,
    600 : #0c2134,
    700 : #0a1b2c,
    800 : #081625,
    900 : #040d18,
    A100 : #005df1,
    A200 : #2579ff,
    A400 : #005df1,
    A700 : #0053d7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// BRACELIT PRIMARY 2
$bee-bracelit2: (
  50 : #ffebee, // #e3ebef // border primary_50
  100 : #ffcdd2, // #baced8 // border primary_100
  200 : #ef9a9a, // #8cadbe  border primary_200
  300 : #e57373,
  400 : #ef5350,
  // 500 : #f44336, // primary
  500 : #dc0d15, // primary
  600 : #e53935,
  700 : #d32f2f,
  800 : #c62828, // #0e3f60 // fill_primary_800
  900 : #b71c1c,
  //A100 : #82bfff, // A SON LOS ACCENT COLORS
  //A200 : #4fa6ff,
  //A400 : #1c8cff,
  //A700 : #037fff,
  // A SON LOS ACCENT COLORS ADAPTADOS AL DISEÑO
  A100 : #ff8a80, // tertiary
  A200 : #ff5252,
  // A400 : #ff1744, // secondary
  A400 : #e53935, // secondary
  A700 : #d50000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// BRACELIT PRIMARY 3
$bee-bracelit3: (
  50 : #fffde7, // #e3ebef // border primary_50
  100 : #fff9c4, // #baced8 // border primary_100
  200 : #fff59d, // #8cadbe  border primary_200
  300 : #fff176,
  400 : #ffee58,
  500 : #ffeb3b, // primary
  600 : #fdd835,
  700 : #fbc02d,
  800 : #f9a825, // #0e3f60 // fill_primary_800
  900 : #f57f17,
  //A100 : #82bfff, // A SON LOS ACCENT COLORS
  //A200 : #4fa6ff,
  //A400 : #1c8cff,
  //A700 : #037fff,
  // A SON LOS ACCENT COLORS ADAPTADOS AL DISEÑO
  A100 : #ffff8d, // tertiary
  A200 : #ffff00,
  A400 : #ffea00, // secondary
  A700 : #ffd600,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// B THE TRAVEL
$bthetravel: (
  50 : #e3f2fd, // #e3ebef // border primary_50
  100 : #bbdefb, // #baced8 // border primary_100
  200 : #90caf9, // #8cadbe  border primary_200
  300 : #64b5f6,
  400 : #42a5f5,
  500 : #0484cc, // primary
  600 : #1e88e5,
  700 : #1976d2,
  800 : #1565c0, // #0e3f60 // fill_primary_800
  900 : #0d47a1,
  //A100 : #82bfff, // A SON LOS ACCENT COLORS
  //A200 : #4fa6ff,
  //A400 : #1c8cff,
  //A700 : #037fff,
  // A SON LOS ACCENT COLORS ADAPTADOS AL DISEÑO
  A100 : #82b1ff, // tertiary
  A200 : #448aff,
  A400 : #2979ff, // secondary
  A700 : #2962ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// GAYHOTELS
$gayhotels: (
  50 : #e2ebff,
  100 : #b6cdfe,
  200 : #85acfe,
  300 : #548afe,
  400 : #2f71fd,
  500 : #0a58fd,
  600 : #0950fd,
  700 : #0747fc,
  800 : #053dfc,
  900 : #032dfc,
  A100 : #ffffff,
  A200 : #eff1ff,
  A400 : #bcc4ff,
  A700 : #a2adff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// GRANADA CF
$granadacf: (
  50 : #f4e4e6,
  100 : #e4bbbf,
  200 : #d38d95,
  300 : #c15f6b,
  400 : #b33d4b,
  500 : #a61b2b,
  600 : #9e1826,
  700 : #951420,
  800 : #8b101a,
  900 : #7b0810,
  A100 : #ff444d,
  A200 : #ff777d,
  A400 : #ff444d,
  A700 : #ff2a35,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// PRUEBAS GALLEGO
$gallego: (
  50 : #e3f2fd, // #e3ebef // border primary_50
  100 : #bbdefb, // #baced8 // border primary_100
  200 : #90caf9, // #8cadbe  border primary_200
  300 : #64b5f6,
  400 : #42a5f5,
  500 : #000080, // primary
  600 : #1e88e5,
  700 : #1976d2,
  800 : #1565c0, // #0e3f60 // fill_primary_800
  900 : #0d47a1,
  //A100 : #82bfff, // A SON LOS ACCENT COLORS
  //A200 : #4fa6ff,
  //A400 : #1c8cff,
  //A700 : #037fff,
  // A SON LOS ACCENT COLORS ADAPTADOS AL DISEÑO
  A100 : #82b1ff, // tertiary
  A200 : #448aff,
  A400 : #2979ff, // secondary
  A700 : #2962ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// EXPOLIVA
$expoliva: (
  50 : #e7eae7,
  100 : #c3c9c2,
  200 : #9ca699,
  300 : #748270,
  400 : #566752,
  500 : #384c33,
  600 : #32452e,
  700 : #2b3c27,
  800 : #243320,
  900 : #172414,
  A100 : #27E700,
  A200 : #27E700,
  A400 : #27E700,
  A700 : #27e700,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// WEGOW
$wegow: (
  50 : #e5e4e5,
  100 : #bdbbbd,
  200 : #928e92,
  300 : #666066,
  400 : #453e45,
  500 : #241c24,
  600 : #201920,
  700 : #1b141b,
  800 : #161116,
  900 : #0d090d,
  A100 : #241c24,
  A200 : #241c24,
  A400 : #241c24,
  A700 : #241c24,
  contrast: (
    50 : #2cf9e9,
    100 : #2cf9e9,
    200 : #2cf9e9,
    300 : #2cf9e9,
    400 : #2cf9e9,
    500 : #2cf9e9,
    600 : #2cf9e9,
    700 : #2cf9e9,
    800 : #2cf9e9,
    900 : #2cf9e9,
    A100 : #2cf9e9,
    A200 : #2cf9e9,
    A400 : #2cf9e9,
    A700 : #2cf9e9,
  )
);

// VANS
$vans: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// GUARAPO
$guarapo: (
  50 : #f2e6f9,
  100 : #ddc1f0,
  200 : #c798e7,
  300 : #b16fdd,
  400 : #a050d5,
  500 : #8f31ce,
  600 : #872cc9,
  700 : #7c25c2,
  800 : #721fbc,
  900 : #6013b0,
  A100 : #b77bff,
  A200 : #d3aeff,
  A400 : #b77bff,
  A700 : #a962ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// PORTAVENTURA
$portaventura: (
  50 : #e3e3f3,
  100 : #b9b9e1,
  200 : #8b8acd,
  300 : #5c5bb9,
  400 : #3937aa,
  500 : #16149b,
  600 : #131293,
  700 : #100e89,
  800 : #0c0b7f,
  900 : #06066d,
  A100 : #3737ff,
  A200 : #6a6aff,
  A400 : #3737ff,
  A700 : #1e1eff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// FARO GASOLINERA
$faro: (
  50 : #ffe9e5,
  100 : #ffc8be,
  200 : #ffa493,
  300 : #ff8068,
  400 : #ff6447,
  500 : #ff4927,
  600 : #ff4223,
  700 : #ff391d,
  800 : #ff3117,
  900 : #ff210e,
  A100 : #ff6447,
  A200 : #fff7f6,
  A400 : #ff6447,
  A700 : #ffafaa,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// BARROKOS
$barrokos: (
  50 : #ebe0e9,
  100 : #ceb3c9,
  200 : #ae80a5,
  300 : #8d4d81,
  400 : #742666,
  500 : #5c004b,
  600 : #540044,
  700 : #4a003b,
  800 : #410033,
  900 : #300023,
  A100 : #ff03ab,
  A200 : #ff36bc,
  A400 : #ff03ab,
  A700 : #e8009b,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// BNP
$bnp: (
  50 : #e0f1ed,
  100 : #b3dcd2,
  200 : #80c5b5,
  300 : #4dae97,
  400 : #269c80,
  500 : #008b6a,
  600 : #008362,
  700 : #007857,
  800 : #006e4d,
  900 : #005b3c,
  A100 : #269C80,
  A200 : #59ffba,
  A400 : #269C80,
  A700 : #0dff9b,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// CARIBBEAN LAKE PARK
$caribean: (
  50 : #ebedf2,
  100 : #ccd3de,
  200 : #abb6c8,
  300 : #8999b2,
  400 : #6f83a2,
  500 : #566d91,
  600 : #4f6589,
  700 : #455a7e,
  800 : #3c5074,
  900 : #2b3e62,
  A100 : #4581ff, //SECONDARY
  A200 : #78a3ff,
  A400 : #4581ff, //TERTIARY
  A700 : #2b6fff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

// SIFER
$sifer: (
  50 : #fef5e0,
  100 : #fce5b3,
  200 : #fbd480,
  300 : #f9c24d,
  400 : #f7b526,
  500 : #f6a800,
  600 : #f5a000,
  700 : #f39700,
  800 : #f28d00,
  900 : #ef7d00,
  A100 : #f7b526,
  A200 : #fff0e3,
  A400 : #f7b526,
  A700 : #ffc796,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// TFDC
$tfdc: (
  50 : #fdf6e0,
  100 : #fbeab3,
  200 : #f8dc80,
  300 : #f5cd4d,
  400 : #f2c326,
  500 : #f0b800,
  600 : #eeb100,
  700 : #eca800,
  800 : #e9a000,
  900 : #e59100,
  A100 : #f2c326,
  A200 : #ffefd9,
  A400 : #f2c326,
  A700 : #ffd08c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// GRANADA SOCIAL
$granadaSocial: (
  50 : #e6edf6,
  100 : #c0d3e7,
  200 : #97b5d8,
  300 : #6d97c8,
  400 : #4d81bc,
  500 : #2e6bb0,
  600 : #2963a9,
  700 : #2358a0,
  800 : #1d4e97,
  900 : #123c87,
  A100 : #548bff,
  A200 : #87adff,
  A400 : #548bff,
  A700 : #3b79ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

// BARBADILLO
$barbadillo: (
  50 : #e0e9e1,
  100 : #b3c8b5,
  200 : #80a483,
  300 : #4d7f51,
  400 : #26632c,
  500 : #004807,
  600 : #004106,
  700 : #003805,
  800 : #003004,
  900 : #002102,
  A100 : #4d7f51,
  A200 : #2aff2a,
  A400 : #4d7f51,
  A700 : #00dd00,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// SUN&SNOW
$sunsnow: (
  50 : #e0f2fb,
  100 : #b3e0f4,
  200 : #80cbed,
  300 : #4db6e5,
  400 : #26a6e0,
  500 : #0096da,
  600 : #008ed6,
  700 : #0083d0,
  800 : #0079cb,
  900 : #0068c2,
  A100 : #26a6e0,
  A200 : #b8d9ff,
  A400 : #26a6e0,
  A700 : #6bb1ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// IBERO EXPERIA
$iberoexperia: (
  50 : #e5e4e5,
  100 : #bdbbbd,
  200 : #928e92,
  300 : #666066,
  400 : #453e45,
  500 : #241c24,
  600 : #201920,
  700 : #1b141b,
  800 : #161116,
  900 : #0d090d,
  A100 : #241c24,
  A200 : #241c24,
  A400 : #241c24,
  A700 : #241c24,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// ISLA MÁGICA
$islamagica: (
  50 : #fff6e3,
  100 : #ffe9ba,
  200 : #ffdb8c,
  300 : #ffcd5d,
  400 : #ffc23b,
  500 : #ffb718,
  600 : #ffb015,
  700 : #ffa711,
  800 : #ff9f0e,
  900 : #ff9008,
  A100 : #ffcd5d,
  A200 : #fffaf4,
  A400 : #ffcd5d,
  A700 : #ffd4a7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* REAL BETIS */
$realbetis: (
  50 : #e0f2eb,
  100 : #b3e0cc,
  200 : #80cbaa,
  300 : #4db688,
  400 : #26a66f,
  500 : #009655,
  600 : #008e4e,
  700 : #008344,
  800 : #00793b,
  900 : #00682a,
  A100 : #31ff73,
  A200 : #64ff95,
  A400 : #31ff73,
  A700 : #18ff61,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* LA PULSE */
$lapulse: (
  50 : #fff8e5,
  100 : #ffeebf,
  200 : #ffe394,
  300 : #fed769,
  400 : #fec629,
  500 : #fec629,
  600 : #fec024,
  700 : #feb91f,
  800 : #feb119,
  900 : #fda40f,
  A100 : #a77934,
  A200 : #fffbf6,
  A400 : #a77934,
  A700 : #fecf49,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* GRANADA BEER FESTIVAL */
$granadabeerfestival: (
  50 : #f0e6ee,
  100 : #dac2d5,
  200 : #c299b9,
  300 : #a9709d,
  400 : #965188,
  500 : #843273,
  600 : #7c2d6b,
  700 : #712660,
  800 : #671f56,
  900 : #541343,
  A100 : #F7AE46,
  A200 : #ff5bd0,
  A400 : #F29EA0,
  A700 : #ff0eba,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

/* CONEXION VALLADOLID */
$conexionvalladolid: (
  50 : #e6f6f5,
  100 : #c2e9e6,
  200 : #99dad6,
  300 : #70cbc5,
  400 : #51bfb8,
  500 : #32b4ac,
  600 : #2dada5,
  700 : #26a49b,
  800 : #1f9c92,
  900 : #138c82,
  A100 : #5afff0,
  A200 : #8dfff4,
  A400 : #5afff0,
  A700 : #41ffed,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* EN ORBITA */
$enorbita: (
  50 : #eeeefb,
  100 : #d4d4f4,
  200 : #b8b8ed,
  300 : #9b9ce6,
  400 : #8586e0,
  500 : #7071db,
  600 : #6869d7,
  700 : #5d5ed2,
  800 : #5354cd,
  900 : #4142c4,
  A100 : #b0b0ff,
  A200 : #e3e3ff,
  A400 : #b0b0ff,
  A700 : #9697ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* PREMIOS DUX 2022 */
$premiosdux2022: (
    50 : #fff9e1,
    100 : #ffefb4,
    200 : #ffe582,
    300 : #fedb4f,
    400 : #fed32a,
    500 : #fecb04,
    600 : #fec603,
    700 : #febe03,
    800 : #feb802,
    900 : #fdac01,
    A100 : #ffdea4,
    A200 : #fffaf1,
    A400 : #ffdea4,
    A700 : #ffdea4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* CASETA JUAN */
$casetajuan: (
    50 : #e2e4e3,
    100 : #b8bbb9,
    200 : #888e8b,
    300 : #58605d,
    400 : #353e3a,
    500 : #111c17,
    600 : #0f1914,
    700 : #0c1411,
    800 : #0a110d,
    900 : #050907,
    A100 : #00e969,
    A200 : #1dff83,
    A400 : #00e969,
    A700 : #00d05e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* ZLIVE */
$zlive: (
    50 : #e4e4e4,
    100 : #bbbbbc,
    200 : #8e8e90,
    300 : #616064,
    400 : #3f3e42,
    500 : #1d1c21,
    600 : #1a191d,
    700 : #151418,
    800 : #111114,
    900 : #0a090b,
    A100 : #a47817,
    A200 : #1f1fff,
    A400 : #a47817,
    A700 : #0000d2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* SEGOROCK */
$segorock: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #dcad61,
    A200 : #1f1fff,
    A400 : #dcad61,
    A700 : #0000d2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* DCanela */
$dcanela: (
    50 : #fbe6ef,
    100 : #f6c1d7,
    200 : #f098bc,
    300 : #ea6ea1,
    400 : #e64f8d,
    500 : #e13079,
    600 : #dd2b71,
    700 : #d92466,
    800 : #d51e5c,
    900 : #cd1349,
    A100 : #ff96b1,
    A200 : #ffc9d7,
    A400 : #ff96b1,
    A700 : #ff7d9e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* CARAMBIRUBI */
$carambirubi: (
    50 : #fce4e5,
    100 : #f7bdbf,
    200 : #f29194,
    300 : #ed6469,
    400 : #e94348,
    500 : #e52228,
    600 : #e21e24,
    700 : #de191e,
    800 : #da1418,
    900 : #d30c0f,
    A100 : #ff9899,
    A200 : #ffcbcc,
    A400 : #ff9899,
    A700 : #ff7f80,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// LA QUE TE DIJE
$laquetedije: (
  50 : #e4e8ed,
  100 : #bcc5d3,
  200 : #8f9fb5,
  300 : #627997,
  400 : #405c81,
  500 : #1e3f6b,
  600 : #1a3963,
  700 : #163158,
  800 : #12294e,
  900 : #0a1b3c,
  A100 : #18A1B9,
  A200 : #2660ff,
  // A400 : #5df897,
  A400 : #18A1B9,
  A700 : #5DF897,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

/* POR PETENERAS */
$porpeteneras: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #E61925,
    A200 : #1f1fff,
    A400 : #E61925,
    A700 : #0000d2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* ADN FESTIVAL */
$adnfestival: (
    50 : #f2e3e9,
    100 : #deb9c9,
    200 : #c98aa5,
    300 : #b35b81,
    400 : #a23866,
    500 : #92154b,
    600 : #8a1244,
    700 : #7f0f3b,
    800 : #750c33,
    900 : #630623,
    A100 : #ff2e63,
    A200 : #ff6189,
    A400 : #ff2e63,
    A700 : #ff1550,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* MONOLOCO SUMMER */
$monolocosummer: (
    50 : #e0f8f9,
    100 : #b3edef,
    200 : #80e1e5,
    300 : #4dd4db,
    400 : #26cbd3,
    500 : #00c2cb,
    600 : #00bcc6,
    700 : #00b4be,
    800 : #00acb8,
    900 : #009fac,
    A100 : #6ff3ff,
    A200 : #a2f7ff,
    A400 : #6ff3ff,
    A700 : #56f0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* EL REBUJITO ISLA MAYOR */
$elrebujitoislamayor: (
    50 : #e0e9f5,
    100 : #b3c9e6,
    200 : #80a5d6,
    300 : #4d80c6,
    400 : #2665b9,
    500 : #004aad,
    600 : #0043a6,
    700 : #003a9c,
    800 : #003293,
    900 : #002283,
    A100 : #4a6aff,
    A200 : #7d94ff,
    A400 : #4a6aff,
    A700 : #3056ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* VESU */
$vesu: (
    50 : #fdedf3,
    100 : #f9d3e1,
    200 : #f6b6ce,
    300 : #f299ba,
    400 : #ef83ab,
    500 : #ec6d9c,
    600 : #ea6594,
    700 : #e75a8a,
    800 : #e45080,
    900 : #df3e6e,
    A100 : #ffc7d7,
    A200 : #fffafb,
    A400 : #ffc7d7,
    A700 : #ffadc5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* BRISA */
$brisa: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #74BB8F,
    A200 : #1f1fff,
    A400 : #74BB8F,
    A700 : #0000d2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* ATLANTIC FEST */
$atlanticfest: (
    50 : #f3f9f9,
    100 : #e0efef,
    200 : #cce5e5,
    300 : #b7dbdb,
    400 : #a7d3d3,
    500 : #98cbcb,
    600 : #90c6c6,
    700 : #85bebe,
    800 : #7bb8b8,
    900 : #6aacac,
    A100 : #6aacac,
    A200 : #efffff,
    A400 : #6aacac,
    A700 : #a2ffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* AYALA POLO */
$ayalapolo: (
    50 : #e2e5e7,
    100 : #b7bec4,
    200 : #87929d,
    300 : #566675,
    400 : #324658,
    500 : #0e253a,
    600 : #0c2134,
    700 : #0a1b2c,
    800 : #081625,
    900 : #040d18,
    A100 : #005df1,
    A200 : #2579ff,
    A400 : #005df1,
    A700 : #0053d7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


//Linares Deportivo
$linaresdeportivo: (
    50 : #e0e6f4,
    100 : #b3c2e3,
    200 : #8099d0,
    300 : #4d70bd,
    400 : #2651af,
    500 : #0032a1,
    600 : #002d99,
    700 : #00268f,
    800 : #001f85,
    900 : #001374,
    A100 : #a2abff,
    A200 : #6f7cff,
    A400 : #3c4eff,
    A700 : #2336ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* Emergentes Festival */
$emergentesfestival: (
    50 : #fceae3,
    100 : #f8cab9,
    200 : #f3a78a,
    300 : #ee845b,
    400 : #eb6937,
    500 : #e74f14,
    600 : #e44812,
    700 : #e03f0e,
    800 : #dd360b,
    900 : #d72606,
    A100 : #ffa499,
    A200 : #ffd2cc,
    A400 : #ffa499,
    A700 : #ff8e80,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* OFF Soto */
$offsoto: (
    50 : #f9f3fc,
    100 : #f0e2f6,
    200 : #e6cff1,
    300 : #dcbbeb,
    400 : #d5ade6,
    500 : #cd9ee2,
    600 : #c896df,
    700 : #c18cda,
    800 : #ba82d6,
    900 : #ae70cf,
    A100 : #9950C8,
    A200 : #ffffff,
    A400 : #9950C8,
    A700 : #ecc9ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* CONTEMPOPRANEA */
$contempopranea: (
    50 : #f2f8f8,
    100 : #dfeeed,
    200 : #cae3e1,
    300 : #b4d8d5,
    400 : #a4cfcc,
    500 : #94c7c3,
    600 : #8cc1bd,
    700 : #81bab5,
    800 : #77b3ae,
    900 : #65a6a1,
    A100 : #519d97,
    A200 : #e5fffd,
    A400 : #519d97,
    A700 : #99fff6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* Oasis Sound */
$oasissound: (
    50 : #e2e2e4,
    100 : #b7b8bb,
    200 : #87888e,
    300 : #575861,
    400 : #33353f,
    500 : #0f111d,
    600 : #0d0f1a,
    700 : #0b0c15,
    800 : #080a11,
    900 : #04050a,
    A100 : #005de8,
    A200 : #1c77ff,
    A400 : #005de8,
    A700 : #0053ce,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* PLAN BRUTAL */
$planbrutal: (
    50 : #fef5e0,
    100 : #fce5b3,
    200 : #fbd480,
    300 : #f9c24d,
    400 : #ee3d23,
    500 : #f29a38,
    600 : #f5a000,
    700 : #f39700,
    800 : #f28d00,
    900 : #ef7d00,
    A100 : #ee3d23,
    A200 : #fff0e3,
    A400 : #ee3d23,
    A700 : #ffc796,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* PRESTOSO FEST */
$prestosofest: (
    50 : #fce8e1,
    100 : #f8c6b5,
    200 : #f3a083,
    300 : #ee7a51,
    400 : #eb5e2c,
    500 : #e74107,
    600 : #e43b06,
    700 : #e03205,
    800 : #dd2a04,
    900 : #d71c02,
    A100 : #ffa098,
    A200 : #ffcfcb,
    A400 : #ffa098,
    A700 : #ff887f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* Festival Vino Somontano */
$festivalvinosomontano: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #737373,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* MIL FESTIVAL */
$milfestival: (
    50 : #e3e4f6,
    100 : #b8bce8,
    200 : #8990d8,
    300 : #5963c8,
    400 : #3641bd,
    500 : #0B245E, //primary
    600 : #101caa,
    700 : #0d18a1,
    800 : #0a1398,
    900 : #050b88,
    A100 : #0B245E, //secondary
    A200 : #8285ff,
    A400 : #FFF500, //tertiary
    A700 : #0B245E,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* CANELA PARTY */
$canelaparty: (
    50 : #e5eefc,
    100 : #bed4f8,
    200 : #92b7f4,
    300 : #669aef,
    400 : #4685eb,
    500 : #256fe8,
    600 : #2167e5,
    700 : #1b5ce2,
    800 : #1652de,
    900 : #0d40d8,
    A100 : #9eb2ff,
    A200 : #d1dbff,
    A400 : #9eb2ff,
    A700 : #859eff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* LA TAJÁ */
$lataja: (
    50 : #f2e1e0,
    100 : #deb3b3,
    200 : #c88180,
    300 : #b14e4d,
    400 : #a12826,
    500 : #900200,
    600 : #880200,
    700 : #7d0100,
    800 : #730100,
    900 : #610100,
    A100 : #ff2b2b,
    A200 : #ff5e5e,
    A400 : #ff2b2b,
    A700 : #ff1212,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* TRAMUNTANA FESTIVAL */
$tramuntanafestival: (
    50 : #e8e4e9,
    100 : #c5bbc8,
    200 : #9e8ea3,
    300 : #77617e,
    400 : #593f62,
    500 : #3c1d46,
    600 : #361a3f,
    700 : #2e1537,
    800 : #27112f,
    900 : #1a0a20,
    A100 : #ab00f8,
    A200 : #bd2cff,
    A400 : #ab00f8,
    A700 : #9900df,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* DANTZ */
$dantz: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #737373,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* OSA DO MAR */
$osadomar: (
    50 : #e3e3e4,
    100 : #bababb,
    200 : #8c8c8d,
    300 : #5d5e5f,
    400 : #3b3c3d,
    500 : #18191b,
    600 : #151618,
    700 : #111214,
    800 : #0e0e10,
    900 : #080808,
    A100 : #18191b,
    A200 : #ff1d1d,
    A400 : #18191b,
    A700 : #cf0000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* DUO DINAMICO */
$duodinamico: (
    50 : #e9f3fe,
    100 : #c8e0fd,
    200 : #a3ccfb,
    300 : #7eb8f9,
    400 : #62a8f8,
    500 : #4699f7,
    600 : #3f91f6,
    700 : #3786f5,
    800 : #2f7cf3,
    900 : #206bf1,
    A100 : #c2d6ff,
    A200 : #f5f8ff,
    A400 : #c2d6ff,
    A700 : #a8c5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* OBUS BARON ROJO */
$obusbaronrojo: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #737373,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* Caseta Barceló 2022 */
$casetabarcelo2022: (
    50 : #eefafc,
    100 : #d4f3f7,
    200 : #b7ecf2,
    300 : #9ae4ed,
    400 : #84dee9,
    500 : #6ed8e5,
    600 : #66d4e2,
    700 : #5bcede,
    800 : #51c8da,
    900 : #3fbfd3,
    A100 : #3fbfd3,
    A200 : #f0fdff,
    A400 : #3fbfd3,
    A700 : #a3f2ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* PUENTE ROMANO */
$puenteromano: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #737373,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* ONSOLA FEST */
$onsolafest: (
    50 : #eae6f0,
    100 : #cac1d9,
    200 : #a698c0,
    300 : #826fa6,
    400 : #685093,
    500 : #4d3180,
    600 : #462c78,
    700 : #3d256d,
    800 : #341f63,
    900 : #251350,
    A100 : #5c25ff,
    A200 : #8258ff,
    A400 : #5c25ff,
    A700 : #490bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* M4DW */
$m4dw: (
    50 : #e3e2e8,
    100 : #b8b7c5,
    200 : #89889f,
    300 : #595879,
    400 : #36345c,
    500 : #12103f,
    600 : #100e39,
    700 : #0d0c31,
    800 : #0a0929,
    900 : #05051b,
    A100 : #0000f3,
    A200 : #2727ff,
    A400 : #0000f3,
    A700 : #0000da,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

//BRACELIT SECONDARY
$bee-bracelit-secondary: (
    50 : #e0f4f6,
    100 : #b3e4e9,
    200 : #80d2da,
    300 : #4dc0cb,
    400 : #26b3c0,
    500 : #00a5b5, // SECONDARY used as A400 in bee-bracelit
    600 : #009dae,
    700 : #0093a5,
    800 : #008a9d, // used as A700 in bee-bracelit
    900 : #00798d,
    A100 : #b9f3ff,
    A200 : #86eaff,
    A400 : #53e1ff,
    A700 : #3addff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// BRACELIT TERTIARY
$bee-bracelit-tertiary: (
    50 : #edf8f9,
    100 : #d3edf0,
    200 : #b6e2e6,
    300 : #98d6dc,
    400 : #82cdd5,
    500 : #6cc4cd, // TERTIARY // used as A100 in bee-bracelit
    600 : #64bec8,
    700 : #59b6c1,
    800 : #4fafba, // used as A200 in bee-bracelit
    900 : #3da2ae,
    A100 : #feffff,
    A200 : #cbf9ff,
    A400 : #98f3ff,
    A700 : #7ff0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// BRACELIT ACCENT
$bee-bracelit-accent: (
    50 : #fffbf2,
    100 : #fff5df,
    200 : #ffeec9, // #ffeec9 accent_200
    300 : #fee7b3,
    400 : #fee2a3,
    500 : #fedd93, // #fedd93 // accent
    600 : #fed98b,
    700 : #fed480,
    800 : #fecf76,
    900 : #fdc764,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #fff8eb,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// SIFER ACCENT
$sifer-accent: (
  50 : #f6f6f6,
  100 : #e9e9e9,
  200 : #dadada,
  300 : #cbcbcb,
  400 : #c0c0c0,
  500 : #000000,
  600 : #aeaeae,
  700 : #a5a5a5,
  800 : #9d9d9d,
  900 : #8d8d8d,
  A100 : #ffffff,
  A200 : #fef4f4,
  A400 : #ffc0c0,
  A700 : #ffa7a7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// TFDC ACCENT
$tfdc-accent: (
  50 : #f6f6f6,
  100 : #e9e9e9,
  200 : #dadada,
  300 : #cbcbcb,
  400 : #c0c0c0,
  500 : #37A1DB,
  600 : #aeaeae,
  700 : #a5a5a5,
  800 : #9d9d9d,
  900 : #8d8d8d,
  A100 : #ffffff,
  A200 : #fef4f4,
  A400 : #ffc0c0,
  A700 : #ffa7a7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// ISLAMAGICA ACCENT
$islamagica-accent: (
  50 : #f6f6f6,
  100 : #e9e9e9,
  200 : #dadada,
  300 : #cbcbcb,
  400 : #c0c0c0,
  500 : #E6272C,
  600 : #aeaeae,
  700 : #a5a5a5,
  800 : #9d9d9d,
  900 : #8d8d8d,
  A100 : #ffffff,
  A200 : #fef4f4,
  A400 : #ffc0c0,
  A700 : #ffa7a7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* ATLANTIC FEST */

$bee-titeam: (
    50 : #e5f2f4,
    100 : #bddee4,
    200 : #92c9d3,
    300 : #66b3c1,
    400 : #45a2b3,
    500 : #2492a6,
    600 : #208a9e,
    700 : #1b7f95,
    800 : #16758b,
    900 : #0d637b,
    A100 : #adebff,
    A200 : #7adfff,
    A400 : #47d2ff,
    A700 : #2dccff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-red: (
    50 : #fce7e7,
    100 : #f7c4c2,
    200 : #f29c9a,
    300 : #ed7472,
    400 : #e95753,
    500 : #e53935,
    600 : #e23330,
    700 : #de2c28,
    800 : #da2422,
    900 : #d31716,
    A100 : #ffffff,
    A200 : #ffd1d1,
    A400 : #ff9f9e,
    A700 : #ff8585,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-pink: (
    50 : #fae4ec,
    100 : #f3bbcf,
    200 : #ec8db0,
    300 : #e45f90,
    400 : #de3d78,
    500 : #d81b60,
    600 : #d41858,
    700 : #ce144e,
    800 : #c81044,
    900 : #bf0833,
    A100 : #ffe9ed,
    A200 : #ffb6c4,
    A400 : #ff839a,
    A700 : #ff6986,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-purple: (
    50 : #f1e5f5,
    100 : #ddbde6,
    200 : #c792d5,
    300 : #b066c4,
    400 : #9f45b7,
    500 : #8e24aa,
    600 : #8620a3,
    700 : #7b1b99,
    800 : #711690,
    900 : #5f0d7f,
    A100 : #e6b1ff,
    A200 : #d67eff,
    A400 : #c64bff,
    A700 : #be31ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$bee-deep-purple: (
    50 : #ebe8f1,
    100 : #cdc5db,
    200 : #ab9ec3,
    300 : #8977ab,
    400 : #705999,
    500 : #573c87,
    600 : #4f367f,
    700 : #462e74,
    800 : #3c276a,
    900 : #2c1a57,
    A100 : #b195ff,
    A200 : #8b62ff,
    A400 : #662fff,
    A700 : #5315ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bee-deep-green: (
    50 : #e2ede6,
    100 : #b6d3c0,
    200 : #85b696,
    300 : #54986c,
    400 : #2f824d,
    500 : #0a6c2d,
    600 : #096428,
    700 : #075922,
    800 : #054f1c,
    900 : #033d11,
    A100 : #73ff89,
    A200 : #40ff5d,
    A400 : #0dff32,
    A700 : #00f226,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-green: (
    50 : #e8f4e9,
    100 : #c7e3c8,
    200 : #a1d0a3,
    300 : #7bbd7e,
    400 : #5fae63,
    500 : #43a047,
    600 : #3d9840,
    700 : #348e37,
    800 : #2c842f,
    900 : #1e7320,
    A100 : #b0ffb2,
    A200 : #7dff80,
    A400 : #4aff4e,
    A700 : #30ff35,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-turquoise: (
    50 : #e0f1ef,
    100 : #b3dcd7,
    200 : #80c4bd,
    300 : #4daca3,
    400 : #269b8f,
    500 : #00897b,
    600 : #008173,
    700 : #007668,
    800 : #006c5e,
    900 : #00594b,
    A100 : #8bffe9,
    A200 : #58ffdf,
    A400 : #25ffd5,
    A700 : #0bffd0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-cyan: (
    50 : #e1f5f8,
    100 : #b3e6ec,
    200 : #80d6e0,
    300 : #4dc5d4,
    400 : #27b8ca,
    500 : #01acc1,
    600 : #01a5bb,
    700 : #019bb3,
    800 : #0192ab,
    900 : #00829e,
    A100 : #c9f4ff,
    A200 : #96e9ff,
    A400 : #63dfff,
    A700 : #49daff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-blue: (
    50 : #e4f1fc,
    100 : #bcdbf7,
    200 : #8fc4f2,
    300 : #62aced,
    400 : #409ae9,
    500 : #1e88e5,
    600 : #1a80e2,
    700 : #1675de,
    800 : #126bda,
    900 : #0a58d3,
    A100 : #fdfeff,
    A200 : #cadcff,
    A400 : #97bbff,
    A700 : #7eaaff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-deep-blue: (
    50 : #e7e9f5,
    100 : #c4c8e6,
    200 : #9ca4d5,
    300 : #747fc4,
    400 : #5763b8,
    500 : #3948ab,
    600 : #3341a4,
    700 : #2c389a,
    800 : #243091,
    900 : #172180,
    A100 : #b8bdff,
    A200 : #858eff,
    A400 : #515fff,
    A700 : #3848ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bee-yellow: (
    50 : #fffae7,
    100 : #fef3c2,
    200 : #feec9a,
    300 : #fee472,
    400 : #fdde53,
    500 : #fdd835,
    600 : #fdd430,
    700 : #fcce28,
    800 : #fcc822,
    900 : #fcbf16,
    A100 : #ffffff,
    A200 : #fffdf8,
    A400 : #ffeec5,
    A700 : #ffe7ac,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-amber: (
    50 : #f9f2e1,
    100 : #f1dfb5,
    200 : #e7c983,
    300 : #ddb351,
    400 : #d6a32c,
    500 : #cf9307,
    600 : #ca8b06,
    700 : #c38005,
    800 : #bd7604,
    900 : #b26402,
    A100 : #ffeddb,
    A200 : #ffd4a8,
    A400 : #ffba75,
    A700 : #ffad5c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-orange: (
    50 : #f9efe1,
    100 : #f1d6b3,
    200 : #e8bb81,
    300 : #df9f4f,
    400 : #d88b29,
    500 : #d17603,
    600 : #cc6e03,
    700 : #c66302,
    800 : #c05902,
    900 : #b54601,
    A100 : #ffe8de,
    A200 : #ffc5ab,
    A400 : #ffa278,
    A700 : #ff915e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-deep-orange: (
    50 : #f7e7e1,
    100 : #ebc2b5,
    200 : #de9984,
    300 : #d07052,
    400 : #c6522d,
    500 : #bc3308,
    600 : #b62e07,
    700 : #ad2706,
    800 : #a52004,
    900 : #971402,
    A100 : #ffc6c2,
    A200 : #ff978f,
    A400 : #ff685c,
    A700 : #ff5042,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-black: (
    50 : #e4e4e4,
    100 : #bcbcbc,
    200 : #8f8f8f,
    300 : #626262,
    400 : #404040,
    500 : #1e1e1e,
    600 : #1a1a1a,
    700 : #161616,
    800 : #121212,
    900 : #0a0a0a,
    A100 : #e07171,
    A200 : #d74747,
    A400 : #eb0000,
    A700 : #d10000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bee-deep-grey: (
    50 : #eaeaea,
    100 : #cacaca,
    200 : #a7a7a7,
    300 : #838383,
    400 : #696969,
    500 : #4e4e4e,
    600 : #474747,
    700 : #3d3d3d,
    800 : #353535,
    900 : #252525,
    A100 : #f18282,
    A200 : #ec5454,
    A400 : #ff0e0e,
    A700 : #f30000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bee-blue-grey: (
    50 : #eaeeef,
    100 : #ccd4d7,
    200 : #aab7bd,
    300 : #879aa2,
    400 : #6e848e,
    500 : #546e7a,
    600 : #4d6672,
    700 : #435b67,
    800 : #3a515d,
    900 : #293f4a,
    A100 : #95d9ff,
    A200 : #62c7ff,
    A400 : #2fb5ff,
    A700 : #15acff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-grey: (
    50 : #eeeeee,
    100 : #d6d6d6,
    200 : #bababa,
    300 : #9e9e9e,
    400 : #8a8a8a,
    500 : #757575,
    600 : #6d6d6d,
    700 : #626262,
    800 : #585858,
    900 : #454545,
    A100 : #f6afaf,
    A200 : #f18282,
    A400 : #ff4141,
    A700 : #ff2727,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bee-brown: (
    50 : #edeae8,
    100 : #d3c9c6,
    200 : #b6a6a0,
    300 : #99827a,
    400 : #83675e,
    500 : #6d4c41,
    600 : #65453b,
    700 : #5a3c32,
    800 : #50332a,
    900 : #3e241c,
    A100 : #ff9a81,
    A200 : #ff724e,
    A400 : #ff491b,
    A700 : #ff3402,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bee-warn: (
    50 : #fce9e7,
    100 : #f8c8c3,
    200 : #f3a39b,
    300 : #ee7e73,
    400 : #eb6355,
    500 : #e74737,
    600 : #e44031,
    700 : #e0372a,
    800 : #dd2f23,
    900 : #d72016,
    A100 : #ffffff,
    A200 : #ffd6d4,
    A400 : #ffa5a1,
    A700 : #ff8d88,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bee-warn2: (
  50 : #e3ebef, // #e3ebef // border primary_50
  100 : #baced8, // #baced8 // border primary_100
  200 : #8bacbe, // #8cadbe  border primary_200
  300 : #5d8ca4,
  400 : #3b7391,
  500 : #185a7d, // primary
  600 : #155275,
  700 : #11486a,
  800 : #0e3f60, // #0e3f60 // fill_primary_800
  900 : #082e4d,
  //A100 : #82bfff, // A SON LOS ACCENT COLORS
  //A200 : #4fa6ff,
  //A400 : #1c8cff,
  //A700 : #037fff,
  // A SON LOS ACCENT COLORS ADAPTADOS AL DISEÑO
  A100 : #6cc4cd, // tertiary
  A200 : #4fafba,
  A400 : #00a5b5, // secondary
  A700 : #008a9d,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);