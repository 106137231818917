@use '@angular/material' as mat;
@import '../../node_modules/@angular/material/theming';

@mixin bracelit-text-classes($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary-200: mat.get-color-from-palette($primary, 200);
  $primary-contrast: mat.get-contrast-color-from-palette($primary, 500);
  $secondary: mat.get-color-from-palette($primary, A100);
  $tertiary: mat.get-color-from-palette($primary, A400);

  .primary-contrast {
    color: $primary-contrast !important;
  }

  .bold-12px-left-primary {
    font-size: 12px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.34px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-14px-left-primary {
    font-size: 14px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.34px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-14px-left-marine {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.34px !important;
    color: #0F223E !important;
  }

  .light-14px-left-primary {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.34px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-12px-left-grey {
    font-size: 12px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.34px !important;
    color: #A5ABB5 !important;
  }

  .light-14px-left-grey {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.34px !important;
    color: #A5ABB5 !important;
  }

  .light-14px-right-grey {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.34px !important;
    color: #A5ABB5 !important;
  }

  .light-12px-right-primary {
    font-size: 12px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.34px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-12px-right-primary {
    font-size: 12px !important;
    font-weight: 700 !important;
    text-align: right !important;
    letter-spacing: -0.34px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-14px-right-primary {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.34px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-14px-center-primary {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.34px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-16px-left-primary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-16px-left-marine {
    font-size: 16px !important;
    font-weight: 700 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: #0F223E !important;
  }

  .bold-14px-left-primary {
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-14px-left-marine {
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: #0F223E !important;
  }

  .bold-16px-right-primary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: right !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-16px-right-marine {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: right !important;
    letter-spacing: -0.39px !important;
    color: #0F223E !important;
  }

  .bold-16px-center-marine {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: #0F223E !important;
  }

  .bold-16px-center-primary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-16px-center-secondary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: $secondary !important;
  }

  .regular-16px-left-marine {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: #0F223E !important;
  }

  .regular-16px-left-primary {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-16px-left-primary-200 {
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: left !important;
    // letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .mat-checkbox-checked .mat-checkbox-background,
  .mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: mat.get-color-from-palette($primary) !important;
  }

  .regular-16px-center-primary {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-16px-center-marine {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: #0f223e !important;
  }

  .light-16px-left-primary {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-16px-left-marine {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color:  #0f223e !important;
  }

  .light-16px-right-primary {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-16px-left-primary-200 {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: $primary-200 !important;
  }

  .light-16px-center-primary {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-16px-center-marine {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: #0f223e !important;
  }

  .light-16px-left-white {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: $primary-contrast !important;
  }

  .light-16px-center-white {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: white !important;
  }

  .regular-16px-center-white {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    // color: $primary-contrast !important;
    color: white !important;
  }

  .bold-18px-right-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-18px-left-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-18px-center-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-18px-center-marine {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: #0F223E !important;
  }

  .bold-400-18px-left-primary {
    font-size: 18px !important;
    font-weight: 400 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-400-18px-center-primary {
    font-size: 18px !important;
    font-weight: 400 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-18px-left-primary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-18px-left-white {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .light-18px-right-white {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .bold-18px-right-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  button.bold-18px-center-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-18px-center-marine {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: #0f223e !important;
  }

  .regular-30px-center-marine {
    font-size: 30px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: #0f223e !important;
  }

  .regular-30px-center-primary {
    font-size: 30px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-14px-center-accent {
    font-size: 14px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($accent) !important;
  }

  .regular-18px-center-accent {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($accent) !important;
  }

  .light-18px-center-accent {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($accent) !important;
  }

  .light-18px-center-primary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-18px-center-grey {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: #A5ABB5 !important;
  }

  .regular-18px-center-primary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-18px-left-primary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-18px-center-primary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-18px-center-marine {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: #0F223E !important;
  }

  .regular-18px-center-white {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .light-18px-center-white {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .regular-18px-left-primary-200 {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: left !important;
    // letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-18px-right-primary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-18px-left-secondary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: $secondary !important;
  }

  .bold-16px-left-secondary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: $secondary !important;
  }

  .bold-20px-center-marine {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: #0F223E !important;
  }

  .bold-20px-center-primary {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .regular-20px-center-primary {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-20px-center-warn {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: mat.get-color-from-palette($warn) !important;
  }

  .bold-20px-left-primary {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-20px-left-primary {
    font-size: 20px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-18px-center-white {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: $primary-contrast !important;
  }

  .bold-20px-center-white {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: $primary-contrast !important;
  }

  .light-20px-center-white {
    font-size: 20px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: #FFFFFF !important;
  }

  .regular-20px-left-secondary {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: $secondary !important;
  }

  .regular-20px-center-secondary {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: $secondary !important;
  }

  .regular-20px-center-white {
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: center !important;
    //letter-spacing: -0.48px !important;
    color: white !important;
  }

  .regular-20px-center-marine {
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: center !important;
    //letter-spacing: -0.48px !important;
    color: #0F223E !important;
  }

  .regular-16px-center-tertiary {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: $tertiary !important;
  }

  .regular-18px-center-tertiary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: $tertiary !important;
  }

  .regular-20px-center-tertiary {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: $tertiary !important;
  }

  .bold-18px-left-tertiary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: $tertiary !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .bold-20px-left-tertiary {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: $tertiary !important;
  }

  .light-16px-right-tertiary {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.39px !important;
    color: $tertiary !important;
  }

  .bold-22px-left-primary {
    font-size: 22px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.53px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .bold-22px-center-primary {
    font-size: 22px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.53px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-22px-center-primary {
    font-size: 22px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.53px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-22px-left-white {
    font-size: 22px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.53px !important;
    line-height: 30px !important;
    color: $primary-contrast !important;
  }

  .light-22px-left-marine {
    font-size: 22px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.53px !important;
    line-height: 30px !important;
    color: #0F223E !important;
  }

  .light-22px-center-white {
    font-size: 22px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.53px !important;
    line-height: 30px !important;
    color: $primary-contrast !important;
  }

  .light-24px-left-marine {
    font-size: 24px !important;
    font-weight: 300 !important;
    text-align: left !important;
    color: #0F223E !important;
  }

  .light-30px-left-marine {
    font-size: 30px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.96px !important;
    color: #0F223E !important;
  }

  .light-30px-right-primary {
    font-size: 30px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.96px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-30px-center-primary {
    font-size: 30px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.96px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-40px-right-primary {
    font-size: 40px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.96px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .light-20px-right-primary {
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: right !important;
    letter-spacing: -0.96px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .code-pulsera {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: right !important;
    letter-spacing: 0.25px !important;
    color: mat.get-color-from-palette($primary) !important;
  }
  .code-pulsera-number {
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: right !important;
    letter-spacing: 0.25px !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .card-header-container{
    display: flex;
    justify-content: left;
  }
  .card-header{
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding: 12px 15px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: right !important;
    letter-spacing: 0.25px !important;
    color: mat.get-contrast-color-from-palette($primary, 500);
    background-color: mat.get-color-from-palette($primary) !important;
    border-top: 5px !important;
    display: flex;
    align-items: center;

    .mat-card-header-text {
      margin: 0 0px !important;
  }
  }

  .no-bonos-activos{
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .no-bonos-activos-letra{
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .no-bonos-activos-letra-comprar{
    max-width: 320px;
    p{
      color: #A5ABB5;
      font-size: 13px !important;
      // font-weight: normal !important;
    }
  }

  .custom-new-2023 {
    min-width: 245px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    background-color: $secondary !important;
    box-shadow: none !important;
    //border-width: 0.5px !important;
    border-radius: 3px !important;
    border-style: none !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: white !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }

  .custom-new-2023-light {
    min-width: 245px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    //border-width: 0.5px !important;
    border-radius: 3px !important;
    // border-style: none !important;
    border-color: mat.get-color-from-palette($primary) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: mat.get-color-from-palette($primary) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }

  .button-text {
    margin-left: 12px;
    margin-right: 8px;
    color: mat.get-color-from-palette($primary) !important;
  }

  .button-text-marine {
    margin-left: 12px;
    margin-right: 8px;
    color: #0F223E !important;
  }

  .button-text-hidden {
    display: none;
  }

}

* {
  font-family: 'Nunito Sans' !important;
}

