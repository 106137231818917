@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

@mixin bee-theme-selector-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .theme-selector {
    background: mat.get-color-from-palette($primary);
    .theme-selector-icon {
      color: mat.get-color-from-palette($primary);
    }
    &:hover {
      .theme-selector-icon {
        color: mat.get-color-from-palette($background, 'card');
      }
    }
  }
  .theme-picker-menu {
    .theme-picker-swatch {
      .theme-chosen-icon {
        color: mat.get-color-from-palette($background, 'card');
      }
    }
  }
}
