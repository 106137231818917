@import "scss/auth";
@import "scss/modals";
@import "scss/cards";
@import "scss/water-mark";
@import "scss/help";
@import "scss/forms";
@import "scss/common";
@import "scss/bracelit-text-classes";
@import "scss/bracelit-classes";
@import "scss/bracelit-responsive";
@import "assets/fonts/fonts.css";

.stat-container {
  height: auto;
  width: auto;
}

.small-chart {
  max-height: 160px !important;
}

.medium-chart {
  min-height: 180px !important;
  max-height: 220px !important;
}

.large-chart {
  min-height: 240px !important;
}

.chart-card {
  padding: 24px 13px !important;
}

.chart-container-min-height {
  min-height: 300px;
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.responsive-img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.red {
  color: #DE5948;
}

.orange {
  color: #B65E26;
}

.yellow {
  color: #818100;
}

.green {
  color: #359964;
}

// Forms
:host-context {
  .button-container {
    place-content: center !important;
  }

  .save-button {
    margin: 0 !important;
  }
}

.cdk-overlay-container {
  z-index: 9999 !important;
}



/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
