@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();
@import 'theming-palette';
@import 'theming-classes';
@import 'app/shared/bee-components/bee-form/_bee-form-theme.component';
@import 'app/shared/bee-components/bee-form/bee-form-item/_bee-form-item-theme.component';
@import 'app/shared/bee-components/bee-form/bee-selector/_bee-selector-theme.component';
@import 'app/shared/bee-components/common/bee-file-uploader/_bee-file-uploader-theme.component';
@import 'app/shared/bee-components/common/bee-image-preview/_bee-image-preview-theme.component';
@import 'app/shared/bee-components/common/bee-confirm-navigation/_bee-confirm-navigation-theme.component';
@import 'app/shared/bee-components/bracelit-email/bracelit-email-theme.component';
@import 'app/shared/bee-components/bracelit-mobile-header/bracelit-mobile-header-theme.component';
@import 'scss/bracelit-text-classes';

// you only have to add additional components here (instead of in every theme class)
@mixin custom-components-theme($theme) {
  @include bee-form-theme($theme);
  @include bee-form-item-theme($theme);
  @include bee-selector-theme($theme);
  @include bee-file-uploader-theme($theme);
  @include bee-image-preview-theme($theme);
  @include bee-confirm-navigation-theme($theme);
  @include bee-email-theme($theme);
  @include bracelit-mobile-header-theme($theme);
  @include theming-classes($theme);
  @include bracelit-text-classes($theme);
}

$mat-theme-ignore-duplication-warnings: true;

// Default accent and warn
$accent: mat.define-palette(mat.$lime-palette);
$warn: mat.define-palette($bee-warn);
$warn2: mat.define-palette($bee-warn2);


// NEW THEMES 2023
//BRACELIT
$bracelit-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($bracelit),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.bracelit-theme {
  @include mat.all-component-themes($bracelit-theme);
  @include custom-components-theme($bracelit-theme);
}

//GREY-BLUE
$greyblue-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($greyblue),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.greyblue-theme {
  @include mat.all-component-themes($greyblue-theme);
  @include custom-components-theme($greyblue-theme);
}

//ORANGE
$orange-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($orange),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.orange-theme {
  @include mat.all-component-themes($orange-theme);
  @include custom-components-theme($orange-theme);
}

//BLUE
$blue-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($blue),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.blue-theme {
  @include mat.all-component-themes($blue-theme);
  @include custom-components-theme($blue-theme);
}

//RED
$red-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($red),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.red-theme {
  @include mat.all-component-themes($red-theme);
  @include custom-components-theme($red-theme);
}

//LIGHT-PURPLE
$light-purple-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($light-purple),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.light-purple-theme {
  @include mat.all-component-themes($light-purple-theme);
  @include custom-components-theme($light-purple-theme);
}

//LIGHT-BLUE
$light-blue-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($light-blue),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.light-blue-theme {
  @include mat.all-component-themes($light-blue-theme);
  @include custom-components-theme($light-blue-theme);
}

//PURPLE
$purple-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($purple),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.purple-theme {
  @include mat.all-component-themes($purple-theme);
  @include custom-components-theme($purple-theme);
}

//YELLOW
$yellow-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($yellow),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.yellow-theme {
  @include mat.all-component-themes($yellow-theme);
  @include custom-components-theme($yellow-theme);
}

//PINK
$pink-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($pink),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.pink-theme {
  @include mat.all-component-themes($pink-theme);
  @include custom-components-theme($pink-theme);
}

//GREEN
$green-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($green),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.green-theme {
  @include mat.all-component-themes($green-theme);
  @include custom-components-theme($green-theme);
}

//LIGHT-GREEN
$light-green-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($light-green),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.light-green-theme {
  @include mat.all-component-themes($light-green-theme);
  @include custom-components-theme($light-green-theme);
}

//MAHOU - SAN MIGUEL
$mahou-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($mahou),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.mahou-theme {
  @include mat.all-component-themes($mahou-theme);
  @include custom-components-theme($mahou-theme);
}

// Define the default theme (same as the example above).
$bee-bracelit-theme: mat.define-light-theme((
    color: (
        primary: mat.define-palette($bee-bracelit),
        accent: mat.define-palette($bee-bracelit-accent),
        warn: $warn,
    )
));

.bee-bracelit-theme {
  @include mat.all-component-themes($bee-bracelit-theme);
  @include custom-components-theme($bee-bracelit-theme);
}

// Define a red theme (same as the example above).
$bee-bracelit-theme2: mat.define-light-theme((
  color: (
    primary: mat.define-palette($bee-bracelit2),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn2,
  )
));

.bee-bracelit-theme2 {
  @include mat.all-component-themes($bee-bracelit-theme2);
  @include custom-components-theme($bee-bracelit-theme2);
}

// Define a red theme (same as the example above).
$bee-bracelit-theme3: mat.define-light-theme((
  color: (
    primary: mat.define-palette($bee-bracelit3),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.bee-bracelit-theme3 {
  @include mat.all-component-themes($bee-bracelit-theme3);
  @include custom-components-theme($bee-bracelit-theme3);
}

// Define a account theme (same as the example above).
$bthetravel-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($bthetravel),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.bthetravel-theme {
  @include mat.all-component-themes($bthetravel-theme);
  @include custom-components-theme($bthetravel-theme);
}

// Define a gallego theme (same as the example above).
$gallego-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($gallego),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.gallego-theme {
  @include mat.all-component-themes($gallego-theme);
  @include custom-components-theme($gallego-theme);
}

// Define a EXPOLIVA theme (same as the example above).
$expoliva-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($expoliva),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.expoliva-theme {
  @include mat.all-component-themes($expoliva-theme);
  @include custom-components-theme($expoliva-theme);
}


// Define a WEGOW theme (same as the example above).
$wegow-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($wegow),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.wegow-theme {
  @include mat.all-component-themes($wegow-theme);
  @include custom-components-theme($wegow-theme);
}

// Define a VANS theme (same as the example above).
$vans-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($vans),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.vans-theme {
  @include mat.all-component-themes($vans-theme);
  @include custom-components-theme($vans-theme);
}

// Define a GUARAPO theme (same as the example above).
$guarapo-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($guarapo),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.guarapo-theme {
  @include mat.all-component-themes($guarapo-theme);
  @include custom-components-theme($guarapo-theme);
}

// Define a PORTAVENTURA theme (same as the example above).
$portaventura-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($portaventura),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.portaventura-theme {
  @include mat.all-component-themes($portaventura-theme);
  @include custom-components-theme($portaventura-theme);
}

// Define a GAYHOTELS theme (same as the example above).
$gayhotels-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($gayhotels),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.gayhotels-theme {
  @include mat.all-component-themes($gayhotels-theme);
  @include custom-components-theme($gayhotels-theme);
}

// Define a GRANADA CF theme (same as the example above).
$granadacf-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($granadacf),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn2,
  )
));

.granadacf-theme {
  @include mat.all-component-themes($granadacf-theme);
  @include custom-components-theme($granadacf-theme);
}

// Define a FARO theme (same as the example above).
$faro-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($faro),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn2,
  )
));

.faro-theme {
  @include mat.all-component-themes($faro-theme);
  @include custom-components-theme($faro-theme);
}

// Define a BARROKOS theme (same as the example above).
$barrokos-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($barrokos),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.barrokos-theme {
  @include mat.all-component-themes($barrokos-theme);
  @include custom-components-theme($barrokos-theme);
}

// Define a BNP theme (same as the example above).
$bnp-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($bnp),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.bnp-theme {
  @include mat.all-component-themes($bnp-theme);
  @include custom-components-theme($bnp-theme);
}

// Define a CARIBEAN LAKE PARK theme (same as the example above).
$caribean-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($caribean),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.caribean-theme {
  @include mat.all-component-themes($caribean-theme);
  @include custom-components-theme($caribean-theme);
}

// Define a SIFER theme (same as the example above).
$sifer-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($sifer),
    accent: mat.define-palette($sifer-accent),
    warn: $warn,
  )
));

.sifer-theme {
  @include mat.all-component-themes($sifer-theme);
  @include custom-components-theme($sifer-theme);
}

// Define a TFDC theme (same as the example above).
$tfdc-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($tfdc),
    accent: mat.define-palette($tfdc-accent),
    warn: $warn,
  )
));

.tfdc-theme {
  @include mat.all-component-themes($tfdc-theme);
  @include custom-components-theme($tfdc-theme);
}

// Define a GRANADASOCIAL theme (same as the example above).
$granadaSocial-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($granadaSocial),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.granadaSocial-theme {
  @include mat.all-component-themes($granadaSocial-theme);
  @include custom-components-theme($granadaSocial-theme);
}

// Define a BARBADILLO theme (same as the example above).
$barbadillo-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($barbadillo),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.barbadillo-theme {
  @include mat.all-component-themes($barbadillo-theme);
  @include custom-components-theme($barbadillo-theme);
}

// Define a SUN&SNOW theme (same as the example above).
$sunsnow-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($sunsnow),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.sunsnow-theme {
  @include mat.all-component-themes($sunsnow-theme);
  @include custom-components-theme($sunsnow-theme);
}

// Define a IBEROEXPERIA theme (same as the example above).
$iberoexperia-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($iberoexperia),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.iberoexperia-theme {
  @include mat.all-component-themes($iberoexperia-theme);
  @include custom-components-theme($iberoexperia-theme);
}

// Define a ISLAMAGICA theme (same as the example above).
$islamagica-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($islamagica),
    accent: mat.define-palette($islamagica-accent),
    warn: $warn,
  )
));

.islamagica-theme {
  @include mat.all-component-themes($islamagica-theme);
  @include custom-components-theme($islamagica-theme);
}

// Define a REAL BETIS theme (same as the example above).
$realbetis-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($realbetis),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.realbetis-theme {
  @include mat.all-component-themes($realbetis-theme);
  @include custom-components-theme($realbetis-theme);
}

// Define a LA PULSE theme (same as the example above).
$lapulse-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($lapulse),
    accent: mat.define-palette($sifer-accent),
    warn: $warn,
  )
));

.lapulse-theme {
  @include mat.all-component-themes($lapulse-theme);
  @include custom-components-theme($lapulse-theme);
}

// Define a GRANADA BEER FESTIVAL theme (same as the example above).
$granadabeerfestival-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($granadabeerfestival),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.granadabeerfestival-theme {
  @include mat.all-component-themes($granadabeerfestival-theme);
  @include custom-components-theme($granadabeerfestival-theme);
}

// Define a CONEXION VALLADOLID theme (same as the example above).
$conexionvalladolid-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($conexionvalladolid),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.conexionvalladolid-theme {
  @include mat.all-component-themes($conexionvalladolid-theme);
  @include custom-components-theme($conexionvalladolid-theme);
}

// Define a AXXIST theme (same as the example above).
$axxist-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($axxist),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.axxist-theme {
  @include mat.all-component-themes($axxist-theme);
  @include custom-components-theme($axxist-theme);
}

// Define a EN ÓRBITA theme (same as the example above).
$enorbita-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($enorbita),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.enorbita-theme {
  @include mat.all-component-themes($enorbita-theme);
  @include custom-components-theme($enorbita-theme);
}

// Define a PREMIOS DUX 2022 theme (same as the example above).
$premiosdux2022-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($premiosdux2022),
    accent: mat.define-palette($sifer-accent),
    warn: $warn,
  )
));

.premiosdux2022-theme {
  @include mat.all-component-themes($premiosdux2022-theme);
  @include custom-components-theme($premiosdux2022-theme);
}

// Define a CASETA JUAN theme (same as the example above).
$casetajuan-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($casetajuan),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.casetajuan-theme {
  @include mat.all-component-themes($casetajuan-theme);
  @include custom-components-theme($casetajuan-theme);
}

// Define a ZLIVE theme (same as the example above).
$zlive-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($zlive),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.zlive-theme {
  @include mat.all-component-themes($zlive-theme);
  @include custom-components-theme($zlive-theme);
}

// Define a SEGOROCK theme (same as the example above).
$segorock-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($segorock),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.segorock-theme {
  @include mat.all-component-themes($segorock-theme);
  @include custom-components-theme($segorock-theme);
}

// Define a DCANELA theme (same as the example above).
$dcanela-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($dcanela),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn2,
  )
));

.dcanela-theme {
  @include mat.all-component-themes($dcanela-theme);
  @include custom-components-theme($dcanela-theme);
}

// Define a CARAMBIRUBI theme (same as the example above).
$carambirubi-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($carambirubi),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn2,
  )
));

.carambirubi-theme {
  @include mat.all-component-themes($carambirubi-theme);
  @include custom-components-theme($carambirubi-theme);
}

// Define a LA QUE TE DIJE theme (same as the example above).
$laquetedije-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($laquetedije),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.laquetedije-theme {
  @include mat.all-component-themes($laquetedije-theme);
  @include custom-components-theme($laquetedije-theme);
}

// Define a POR PETENERAS theme (same as the example above).
$porpeteneras-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($porpeteneras),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.porpeteneras-theme {
  @include mat.all-component-themes($porpeteneras-theme);
  @include custom-components-theme($porpeteneras-theme);
}

// Define a ADN FESTIVAL theme (same as the example above).
$adnfestival-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($adnfestival),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.adnfestival-theme {
  @include mat.all-component-themes($adnfestival-theme);
  @include custom-components-theme($adnfestival-theme);
}

// Define a MONOLOCO SUMMER theme (same as the example above).
$monolocosummer-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($monolocosummer),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.monolocosummer-theme {
  @include mat.all-component-themes($monolocosummer-theme);
  @include custom-components-theme($monolocosummer-theme);
}

// Define a EL REBUJITO ISLA MAYOR theme (same as the example above).
$elrebujitoislamayor-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($elrebujitoislamayor),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.elrebujitoislamayor-theme {
  @include mat.all-component-themes($elrebujitoislamayor-theme);
  @include custom-components-theme($elrebujitoislamayor-theme);
}

// Define a VESU theme (same as the example above).
$vesu-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($vesu),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.vesu-theme {
  @include mat.all-component-themes($vesu-theme);
  @include custom-components-theme($vesu-theme);
}

// Define a BRISA theme (same as the example above).
$brisa-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($brisa),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.brisa-theme {
  @include mat.all-component-themes($brisa-theme);
  @include custom-components-theme($brisa-theme);
}

// Define a ATLANTIC FEST theme (same as the example above).
$atlanticfest-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($atlanticfest),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.atlanticfest-theme {
  @include mat.all-component-themes($atlanticfest-theme);
  @include custom-components-theme($atlanticfest-theme);
}

// Define a AYALA POLO theme (same as the example above).
$ayalapolo-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($ayalapolo),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.ayalapolo-theme {
  @include mat.all-component-themes($ayalapolo-theme);
  @include custom-components-theme($ayalapolo-theme);
}

// Define a LINARES DEPORTIVO theme (same as the example above).
$linaresdeportivo-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($linaresdeportivo),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.linaresdeportivo-theme {
  @include mat.all-component-themes($linaresdeportivo-theme);
  @include custom-components-theme($linaresdeportivo-theme);
}

// Define a EMERGENTES FESTIVAL theme (same as the example above).
$emergentesfestival-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($emergentesfestival),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.emergentesfestival-theme {
  @include mat.all-component-themes($emergentesfestival-theme);
  @include custom-components-theme($emergentesfestival-theme);
}

// Define a OFF Soto theme (same as the example above).
$offsoto-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($offsoto),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.offsoto-theme {
  @include mat.all-component-themes($offsoto-theme);
  @include custom-components-theme($offsoto-theme);
}

// Define a contemPOPranea theme (same as the example above).
$contempopranea-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($contempopranea),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.contempopranea-theme {
  @include mat.all-component-themes($contempopranea-theme);
  @include custom-components-theme($contempopranea-theme);
}

// Define a Oasis Sound theme (same as the example above).
$oasissound-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($oasissound),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.oasissound-theme {
  @include mat.all-component-themes($oasissound-theme);
  @include custom-components-theme($oasissound-theme);
}

// Define a Plan Brutal theme (same as the example above).
$planbrutal-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($planbrutal),
    accent: mat.define-palette($sifer-accent),
    warn: $warn,
  )
));

.planbrutal-theme {
  @include mat.all-component-themes($planbrutal-theme);
  @include custom-components-theme($planbrutal-theme);
}

// Define a PRESTOSO FEST theme (same as the example above).
$prestosofest-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($prestosofest),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.prestosofest-theme {
  @include mat.all-component-themes($prestosofest-theme);
  @include custom-components-theme($prestosofest-theme);
}

// Define a Festival Vino Somontano theme (same as the example above).
$festivalvinosomontano-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($festivalvinosomontano),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.festivalvinosomontano-theme {
  @include mat.all-component-themes($festivalvinosomontano-theme);
  @include custom-components-theme($festivalvinosomontano-theme);
}

// Define a Mil Festival theme (same as the example above).
$milfestival-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($milfestival),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.milfestival-theme {
  @include mat.all-component-themes($milfestival-theme);
  @include custom-components-theme($milfestival-theme);
}

// Define a Canela Party theme (same as the example above).
$canelaparty-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($canelaparty),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.canelaparty-theme {
  @include mat.all-component-themes($canelaparty-theme);
  @include custom-components-theme($canelaparty-theme);
}

// Define a La Tajá theme (same as the example above).
$lataja-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($lataja),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.lataja-theme {
  @include mat.all-component-themes($lataja-theme);
  @include custom-components-theme($lataja-theme);
}

// Define a Tramuntana Festival theme (same as the example above).
$tramuntanafestival-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($tramuntanafestival),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.tramuntanafestival-theme {
  @include mat.all-component-themes($tramuntanafestival-theme);
  @include custom-components-theme($tramuntanafestival-theme);
}

// Define a DAN-TZ theme (same as the example above).
$dantz-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($dantz),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.dantz-theme {
  @include mat.all-component-themes($dantz-theme);
  @include custom-components-theme($dantz-theme);
}

// Define a OSA DO MAR theme (same as the example above).
$osadomar-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($osadomar),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.osadomar-theme {
  @include mat.all-component-themes($osadomar-theme);
  @include custom-components-theme($osadomar-theme);
}

// Define a DUO DINAMICO theme (same as the example above).
$duodinamico-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($duodinamico),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.duodinamico-theme {
  @include mat.all-component-themes($duodinamico-theme);
  @include custom-components-theme($duodinamico-theme);
}

// Define a OBUS BARONROJO theme (same as the example above).
$obusbaronrojo-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($obusbaronrojo),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.obusbaronrojo-theme {
  @include mat.all-component-themes($obusbaronrojo-theme);
  @include custom-components-theme($obusbaronrojo-theme);
}

// Define a CASETA BARCELÓ 2022 theme (same as the example above).
$casetabarcelo2022-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($casetabarcelo2022),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.casetabarcelo2022-theme {
  @include mat.all-component-themes($casetabarcelo2022-theme);
  @include custom-components-theme($casetabarcelo2022-theme);
}

// Define a PUENTE ROMANO theme (same as the example above).
$puenteromano-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($puenteromano),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.puenteromano-theme {
  @include mat.all-component-themes($puenteromano-theme);
  @include custom-components-theme($puenteromano-theme);
}

// Define a ONSOLA FEST theme
$onsolafest-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($onsolafest),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.onsolafest-theme {
  @include mat.all-component-themes($onsolafest-theme);
  @include custom-components-theme($onsolafest-theme);
}

// Define a M4DW theme
$m4dw-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($m4dw),
    accent: mat.define-palette($bee-bracelit-accent),
    warn: $warn,
  )
));

.m4dw-theme {
  @include mat.all-component-themes($m4dw-theme);
  @include custom-components-theme($m4dw-theme);
}

$bee-titeam-primary: mat.define-palette($bee-titeam);
$bee-titeam-theme: mat.define-light-theme($bee-titeam-primary, $accent, $warn);

.bee-titeam-theme {
  @include mat.all-component-themes($bee-titeam-theme);
  @include custom-components-theme($bee-titeam-theme);
}

$bee-red-primary: mat.define-palette($bee-red);
$bee-red-theme: mat.define-light-theme($bee-red-primary, $accent, $warn);

.bee-red-theme {
  @include mat.all-component-themes($bee-red-theme);
  @include custom-components-theme($bee-red-theme);
}

$bee-pink-primary: mat.define-palette($bee-pink);
$bee-pink-theme: mat.define-light-theme($bee-pink-primary, $accent, $warn);

.bee-pink-theme {
  @include mat.all-component-themes($bee-pink-theme);
  @include custom-components-theme($bee-pink-theme);
}

$bee-purple-primary: mat.define-palette($bee-purple);
$bee-purple-theme: mat.define-light-theme($bee-purple-primary, $accent, $warn);

.bee-purple-theme {
  @include mat.all-component-themes($bee-purple-theme);
  @include custom-components-theme($bee-purple-theme);
}

$bee-deep-purple-primary: mat.define-palette($bee-deep-purple);
$bee-deep-purple-theme: mat.define-light-theme($bee-deep-purple-primary, $accent, $warn);

.bee-deep-purple-theme {
  @include mat.all-component-themes($bee-deep-purple-theme);
  @include custom-components-theme($bee-deep-purple-theme);
}

$bee-deep-green-primary: mat.define-palette($bee-deep-green);
$bee-deep-green-theme: mat.define-light-theme($bee-deep-green-primary, $accent, $warn);

.bee-deep-green-theme {
  @include mat.all-component-themes($bee-deep-green-theme);
  @include custom-components-theme($bee-deep-green-theme);
}

$bee-green-primary: mat.define-palette($bee-green);
$bee-green-theme: mat.define-light-theme($bee-green-primary, $accent, $warn);

.bee-green-theme {
  @include mat.all-component-themes($bee-green-theme);
  @include custom-components-theme($bee-green-theme);
}

$bee-turquoise-primary: mat.define-palette($bee-turquoise);
$bee-turquoise-theme: mat.define-light-theme($bee-turquoise-primary, $accent, $warn);

.bee-turquoise-theme {
  @include mat.all-component-themes($bee-turquoise-theme);
  @include custom-components-theme($bee-turquoise-theme);
}

$bee-cyan-primary: mat.define-palette($bee-cyan);
$bee-cyan-theme: mat.define-light-theme($bee-cyan-primary, $accent, $warn);

.bee-cyan-theme {
  @include mat.all-component-themes($bee-cyan-theme);
  @include custom-components-theme($bee-cyan-theme);
}

$bee-blue-primary: mat.define-palette($bee-blue);
$bee-blue-theme: mat.define-light-theme($bee-blue-primary, $accent, $warn);

.bee-blue-theme {
  @include mat.all-component-themes($bee-blue-theme);
  @include custom-components-theme($bee-blue-theme);
}

$bee-deep-blue-primary: mat.define-palette($bee-deep-blue);
$bee-deep-blue-theme: mat.define-light-theme($bee-deep-blue-primary, $accent, $warn);

.bee-deep-blue-theme {
  @include mat.all-component-themes($bee-deep-blue-theme);
  @include custom-components-theme($bee-deep-blue-theme);
}

$bee-yellow-primary: mat.define-palette($bee-yellow);
$bee-yellow-theme: mat.define-light-theme($bee-yellow-primary, $accent, $warn);

.bee-yellow-theme {
  @include mat.all-component-themes($bee-yellow-theme);
  @include custom-components-theme($bee-yellow-theme);
}

$bee-amber-primary: mat.define-palette($bee-amber);
$bee-amber-theme: mat.define-light-theme($bee-amber-primary, $accent, $warn);

.bee-amber-theme {
  @include mat.all-component-themes($bee-amber-theme);
  @include custom-components-theme($bee-amber-theme);
}

$bee-orange-primary: mat.define-palette($bee-orange);
$bee-orange-theme: mat.define-light-theme($bee-orange-primary, $accent, $warn);

.bee-orange-theme {
  @include mat.all-component-themes($bee-orange-theme);
  @include custom-components-theme($bee-orange-theme);
}

$bee-deep-orange-primary: mat.define-palette($bee-deep-orange);
$bee-deep-orange-theme: mat.define-light-theme($bee-deep-orange-primary, $accent, $warn);

.bee-deep-orange-theme {
  @include mat.all-component-themes($bee-deep-orange-theme);
  @include custom-components-theme($bee-deep-orange-theme);
}

$bee-black-primary: mat.define-palette($bee-black);
$bee-black-theme: mat.define-light-theme($bee-black-primary, $accent, $warn);

.bee-black-theme {
  @include mat.all-component-themes($bee-black-theme);
  @include custom-components-theme($bee-black-theme);
}

$bee-deep-grey-primary: mat.define-palette($bee-deep-grey);
$bee-deep-grey-theme: mat.define-light-theme($bee-deep-grey-primary, $accent, $warn);

.bee-deep-grey-theme {
  @include mat.all-component-themes($bee-deep-grey-theme);
  @include custom-components-theme($bee-deep-grey-theme);
}

$bee-blue-grey-primary: mat.define-palette($bee-blue-grey);
$bee-blue-grey-theme: mat.define-light-theme($bee-blue-grey-primary, $accent, $warn);

.bee-blue-grey-theme {
  @include mat.all-component-themes($bee-blue-grey-theme);
  @include custom-components-theme($bee-blue-grey-theme);
}

$bee-grey-primary: mat.define-palette($bee-grey);
$bee-grey-theme: mat.define-light-theme($bee-grey-primary, $accent, $warn);

.bee-grey-theme {
  @include mat.all-component-themes($bee-grey-theme);
  @include custom-components-theme($bee-grey-theme);
}

$bee-brown-primary: mat.define-palette($bee-brown);
$bee-brown-theme: mat.define-light-theme($bee-brown-primary, $accent, $warn);

.bee-brown-theme {
  @include mat.all-component-themes($bee-brown-theme);
  @include custom-components-theme($bee-brown-theme);
}
