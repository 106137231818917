@media only screen and (max-width: 360px) {
  .bracelit-card-2 {
    width: 320px;
  }
  .empty-state-container {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 360px) {
  .bracelit-card-2 {
    width: 320px;
  }
  .empty-state-container {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 414px) {
  .bracelit-card-wristbands {
    width: 125px;
  }
}

@media only screen and (min-width: 414px) {
  .bracelit-card-wristbands {
    width: 150px;
  }
}

@media only screen and (max-width: 414px) {
  .bracelit-card, .bracelit-card-width {
    width: 320px;
  }
}

@media only screen and (min-width: 414px) {
  .bracelit-card, .bracelit-card-width {
    width: 320px;
  }
  .bracelit-card-2 {
    width: 320px;
  }
}
