$easing: cubic-bezier(.6, .05, .28, .91);

.dialog-custom-input {
  text-align: left;
  border-top: none;
  border-right: none;
  border-left: none;
  font-family: 'Nunito Sans' !important;
  font-size: 14px;
  padding: 5px 5px 5px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex: 1 1 95%;
  box-sizing: border-box;
  max-width: 95%;
  width: 95%;
  transition: border-bottom-color 350ms $easing;
  &:focus {
    outline: none;
    padding-bottom: 4px;
    border-bottom-width: 2px;
  }
  &:hover {
    padding-bottom: 4px;
    border-bottom-width: 2px;
  }
  &.left-aligned {
    text-align: left;
  }
}
