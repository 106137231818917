@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

@mixin bee-file-uploader-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .model-files-content {
    .model-files-table {
      tr {
        border-top-color: mat.get-color-from-palette($foreground, divider);
        .action-icon {
          color: transparent;
        }
        &:hover {
          background: mat.get-color-from-palette($background, hover, 0.03);
          .action-icon {
            color: mat.get-color-from-palette($foreground, secondary-text);
            &:hover {
              color: mat.get-color-from-palette($primary);
            }
          }
        }
      }
    }
  }

  .image-container {
    outline-color: mat.get-color-from-palette($foreground, divider) !important;
    .preview-icon {
      color: transparent;
    }
    &:hover {
      opacity: 0.8;
      -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
      filter: grayscale(50%);
      .preview-icon {
        color: mat.get-color-from-palette($background, card);
      }
    }
  }

  .table-mode-actions, .model-files-footer-icon {
    color: mat.get-color-from-palette($primary);
  }
  /************ Table mode ************/


  /************ Circular mode ************/
  .circular-mode-container {
    .circular-mode-image-container {
      border: 2px solid mat.get-color-from-palette($primary);
      .preview-icon {
        color: transparent;
      }
      &:hover {
        opacity: 0.8;
        -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
        filter: grayscale(50%);
        .preview-icon {
          color: mat.get-color-from-palette($background, card);
        }
      }
      &.circular-image-container-empty {
        &:hover {
          .preview-icon {
            color: mat.get-color-from-palette($foreground, text);
          }
        }
      }
    }
    .circular-mode-actions-container {
      .circular-mode-actions {
        color: mat.get-color-from-palette($primary);
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  /************ Square mode ************/
  .square-mode-container {
    .square-mode-button-container {
      border-color: mat.get-color-from-palette($foreground, divider);
      &:hover {
        border-color: mat.get-color-from-palette($foreground, text);
      }
      color: mat.get-color-from-palette($foreground, text, 0.8);
    }

    .square-mode-image-container {
      outline-color: mat.get-color-from-palette($foreground, divider) !important;
      .preview-icon {
        color: transparent;
      }
      &:hover {
        opacity: 0.8;
        -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
        filter: grayscale(50%);
        .preview-icon {
          color: mat.get-color-from-palette($background, card);
        }
      }
    }
    .square-mode-actions-container {
      .square-mode-delete-action {
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover {
          color: mat.get-color-from-palette($primary);
        }
      }
      .square-mode-edit-action {
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover {
          color: mat.get-color-from-palette($primary);
        }
      }
    }
  }

  /************ Compound mode ************/
  .compound-mode-container {
    .compound-mode-button-container {
      border-color: mat.get-color-from-palette($foreground, divider);
      &:hover {
        border-color: mat.get-color-from-palette($foreground, text);
      }
      color: mat.get-color-from-palette($foreground, text, 0.8);
    }

    .compound-mode-image-container {
      outline-color: mat.get-color-from-palette($foreground, divider) !important;
      .preview-icon {
        color: transparent;
      }
      &:hover {
        opacity: 0.8;
        -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
        filter: grayscale(50%);
        .preview-icon {
          color: mat.get-color-from-palette($background, card);
        }
      }
    }
    .compound-mode-actions-container {
      .compound-mode-delete-action {
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover {
          color: mat.get-color-from-palette($primary);
        }
      }
      .compound-mode-edit-action {
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover {
          color: mat.get-color-from-palette($primary);
        }
      }
    }
  }

  /************ Mobile mode ************/
  .mobile-mode-container {
    .mobile-mode-button-container {
      border-color: mat.get-color-from-palette($foreground, divider);
      &:hover {
        border-color: mat.get-color-from-palette($foreground, text);
      }
      color: mat.get-color-from-palette($foreground, text, 0.8);
    }

    .mobile-mode-image-container {
      outline-color: mat.get-color-from-palette($foreground, divider) !important;
      .preview-icon {
        color: transparent;
      }
      &:hover {
        opacity: 0.8;
        -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
        filter: grayscale(50%);
        .preview-icon {
          color: mat.get-color-from-palette($background, card);
        }
      }
    }
    .mobile-mode-actions-container {
      .mobile-mode-delete-action {
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover {
          color: mat.get-color-from-palette($primary);
        }
      }
      .mobile-mode-edit-action {
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover {
          color: mat.get-color-from-palette($primary);
        }
      }
    }
  }

  /************ Common ************/
  .dimensions-tip {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }
}
